import React, { useEffect, useMemo, useState } from "react";
import { Button, Checkbox, Form, Input } from "semantic-ui-react";
// ui ORM
import CardSurgicalSafetyChecklistSignoutUX from "./CardSurgicalSafetyChecklistSignoutUX";
import PreOPButtonAction from "./PreOPButtonAction";

// Common
import SnackMessage from "react-lib/apps/common/SnackMessage";
import TimeComboBox from "react-lib/apps/common/TimeComboBox";
import { useIntl } from "react-intl";

const CARD_KEY: string = "surgicalSafetyChecklistSigninOut";
const FORM_CODE: string = "CardAfterClosureAndWoundCare";
const FORM_NAME: string = "After closure & Wound care";
const FORM_VERSION: string = "0.1";

type CardSurgicalSafetyChecklistSignoutProps = {
  // function
  onEvent: any;
  setProp: any;
  buttonLoadCheck?: any;

  // select OR
  selectedOrOrder?: any;

  // options
  masterOptions?: any;
  masterData?: any;

  // seq
  runSequence?: any;
  PerioperativeNursingSequence?: any;
};

const CardSurgicalSafetyChecklistSignoutInitial: CardSurgicalSafetyChecklistSignoutProps = {
  // funtion
  onEvent: () => null,
  setProp: () => null,
  buttonLoadCheck: null,
};

const Specimen = {
  tissue: "",
  other_specimen: "",
  culture: "",
  is_tissue: false,
  is_other_specimen: false,
  is_frozen: false,
  is_estrogen_rec: false,
  is_cyto_section: false,
  is_culture: false,
};

type OptionType = {
  key: string;
  text: string;
  value: string;
};

type OptionsState = {
  orDrainDetailOption: OptionType[];
  orICDetailOption: OptionType[];
};

const CardSurgicalSafetyChecklistSignout: React.FC<CardSurgicalSafetyChecklistSignoutProps> = (
  props: any
) => {
  const [closureDate, setClosureDate] = useState("");
  const [closureTime, setClosureTime] = useState("");
  const [remark, setRemark] = useState("");
  const [options, setOptions] = useState<OptionsState>({
    orDrainDetailOption: [],
    orICDetailOption: [],
  });

  const surgicalSafety = props.PerioperativeNursingSequence?.surgicalSafetyChecklistSigninOut;

  // useEffect(() => {
  //   props.runSequence({
  //     sequence: "PreOperation",
  //     action: "FETCH_FORM_DATA_LATEST",
  //     cardKey: CARD_KEY,
  //     formCode: FORM_CODE,
  //     formVersion: FORM_VERSION,
  //   });
  // }, []);

  useEffect(() => {
    // setClosureDate(props.PerioperativeNursingSequence?.operatingDetail?.closure_date);
    // setClosureTime(props.PerioperativeNursingSequence?.operatingDetail?.closure_time);
    // setRemark(props.PerioperativeNursingSequence?.operatingDetail?.remark);
    setClosureDate("");
    setClosureTime("");
    setRemark("");
  }, [props.PerioperativeNursingSequence?.operatingDetail]);

  useEffect(() => {
    if (props.buttonLoadCheck?.[`${CARD_KEY}_SAVE`] === "SUCCESS") {
      setClosureDate("");
      setClosureTime("");
      setRemark("");
    }
  }, [props.buttonLoadCheck]);

  const updateOptions = (data: any, masterKey: string, optionsKey: keyof OptionsState) => {
    const masterData = props.masterOptions?.[masterKey] || [];

    // แก้ไขข้อมูลให้อยู่ในรูปแบบ array
    let valueInOption = Array.isArray(data) ? data : [data];

    // ค้นหา option ที่เพิ่มขึ้นมาเอง
    const optionData = (valueInOption || [])
      .filter(
        (item: any) =>
          (item && typeof item === "string") ||
          (item?.medicine_name && typeof item?.medicine_name === "string")
      )
      .map((item) => ({
        key: item,
        text: item,
        value: item,
      }));

    // รวม option จาก masterData และ options (option ที่เพิ่มขึ้นเอง)
    const combinedOption = [...new Set([...masterData, ...(options[optionsKey] || [])])];

    // ตรวจสอบหาเฉพาะ optionData ที่ไม่ซ้ำใน combinedOption
    const newOptions = optionData.filter(
      (item: any) => !combinedOption.some((option) => option.value === item.value)
    );

    setOptions((prevOptions) => ({
      ...prevOptions,
      [optionsKey]: newOptions.length ? [...combinedOption, ...newOptions] : combinedOption,
    }));
  };

  useEffect(() => {
    updateOptions(surgicalSafety?.data?.drain, "orDrainDetail", "orDrainDetailOption");
  }, [props.masterOptions?.orDrainDetail, surgicalSafety?.data?.drain]);

  useEffect(() => {
    updateOptions(surgicalSafety?.data?.incision_closure, "orIncisionClosure", "orICDetailOption");
  }, [props.masterOptions?.orIncisionClosure, surgicalSafety?.data?.incision_closure]);

  const handleChangeValue = (key: string) => (_event: any, data: any) => {
    if (key === "remark") {
      setRemark(data.value);
    } else {
      if (data.type === "checkbox" || data.type === "radio") {
        if (
          [
            // Surgical Safety Checklist - Sign out
            "is_skin_condition",
            "is_blood_administered",
            "is_drain",
            "is_incision_closure",
          ].includes(key) &&
          !data.checked
        ) {
          let keyRemark = key.split("is_");
          props.setProp(`PerioperativeNursingSequence.surgicalSafetyChecklistSigninOut.data`, {
            ...surgicalSafety.data,
            [`${key}`]: data.checked,
            [`${keyRemark?.[1]}`]: "",
          });
        } else {
          props.setProp(
            `PerioperativeNursingSequence.surgicalSafetyChecklistSigninOut.data.${key}`,
            data.checked
          );
        }
      } else {
        props.setProp(
          `PerioperativeNursingSequence.surgicalSafetyChecklistSigninOut.data.${key}`,
          data.value
        );
      }
    }
  };

  const handleChangeDate = (key: string) => (date: any) => {
    setClosureDate(date);
  };

  const handleAddOptions = (e: any, v: any) => {
    let newOption = { text: v.value, value: v.value, key: v.value };

    const optionMap: { [key: string]: keyof OptionsState } = {
      orDrainDetail: "orDrainDetailOption",
      orIncisionClosure: "orICDetailOption",
    };

    const key = optionMap[v.name];
    if (key) {
      setOptions({ ...options, [key]: [...options[key], newOption] });
    }
  };

  const handleSave = (action: string) => {
    if (!closureDate || !closureTime) {
      props.setProp(`errorMessage.${CARD_KEY}`, {
        Closure: ["ไม่สามารถระบุค่าว่างที่ช่องนี้"],
      });
    } else {
      props.runSequence({
        sequence: "PerioperativeNursing",
        action: action,
        cardKey: CARD_KEY,
        formCode: FORM_CODE,
        formName: FORM_NAME,
        formVersion: FORM_VERSION,
        closure_date: closureDate,
        closure_time: closureTime,
        ...(remark && {
          remark: remark,
        }),
      });
    }
  };

  const handleChangeSpecimenComponent = (value: any, index: any, key: any) => {
    props.setProp(
      `PerioperativeNursingSequence.surgicalSafetyChecklistSigninOut.data.specimen_items.${index}.${key}`,
      value
    );
  };

  const handlerAddRound = () => {
    const items: any[] = surgicalSafety?.data?.specimen_items || [];
    items.push({ Specimen });
    props.setProp(
      "PerioperativeNursingSequence.surgicalSafetyChecklistSigninOut.data.specimen_items",
      items
    );
  };

  const handlerDeleteRound = (index: any) => {
    const items: any[] = surgicalSafety?.data?.specimen_items || [];

    if (items.length === 1) {
      props.setProp(
        "PerioperativeNursingSequence.surgicalSafetyChecklistSigninOut.data.specimen_items.0",
        {
          ...surgicalSafety.data?.specimen_items?.[0],
          tissue: "",
          other_specimen: "",
          culture: "",
          is_tissue: false,
          is_other_specimen: false,
          is_frozen: false,
          is_estrogen_rec: false,
          is_cyto_section: false,
          is_culture: false,
        }
      );
    } else if (items.length > 1) {
      items.splice(index, 1);
      props.setProp(
        "PerioperativeNursingSequence.surgicalSafetyChecklistSigninOut.data.specimen_items",
        items
      );
    }
  };

  return (
    <>
      <SnackMessage
        onEvent={props.onEvent}
        onClose={() => {
          props.setProp(`errorMessage.${CARD_KEY}`, null);
        }}
        error={props.errorMessage?.[CARD_KEY]}
        success={null}
        languageUX={props.languageUX}
      />
      <CardSurgicalSafetyChecklistSignoutUX
        surgicalSafety={surgicalSafety}
        masterOptions={props.masterOptions}
        handleChangeValue={handleChangeValue}
        handleChangeDate={handleChangeDate}
        handleAddOptions={handleAddOptions}
        options={options}
        remark={remark}
        closureDate={closureDate}
        // status={surgicalSafety?.status}
        specimenComponent={
          <Form
            style={{
              display: surgicalSafety?.data?.no_specimen ? "" : "none",
              width: "100%",
              marginTop: "1rem",
            }}
          >
            {surgicalSafety?.data?.specimen_items?.map((item: any, index: any) => {
              return (
                <RoundData
                  item={item}
                  index={index}
                  onChangeSpecimenComponent={handleChangeSpecimenComponent}
                  handlerDeleteRound={handlerDeleteRound}
                  handlerAddRound={handlerAddRound}
                />
              );
            })}
          </Form>
        }
        buttonSave={
          <PreOPButtonAction
            setProp={props.setProp}
            // data={props.PerioperativeNursingSequence}
            type="save"
            cardKey={CARD_KEY}
            buttonLoadCheck={props.buttonLoadCheck}
            onClick={() => handleSave("SAVE")}
          />
        }
        // buttonConfirm={
        //   <PreOPButtonAction
        //     setProp={props.setProp}
        //     data={props.PerioperativeNursingSequence}
        //     type="confirm"
        //     cardKey={CARD_KEY}
        //     buttonLoadCheck={props.buttonLoadCheck}
        //     onClick={() => handleSave("CONFIRM")}
        //   />
        // }
        // buttonUnconfirm={
        //   <PreOPButtonAction
        //     setProp={props.setProp}
        //     data={props.PerioperativeNursingSequence}
        //     type="unconfirm"
        //     cardKey={CARD_KEY}
        //     buttonLoadCheck={props.buttonLoadCheck}
        //     onClick={() => handleSave("UNCONFIRM")}
        //   />
        // }
        timeTextBox={
          <TimeComboBox
            noMinWidth={true}
            defaultValue={closureTime || ""}
            onTextChange={(time) => setClosureTime(time)}
          />
        }
        languageUX={props.languageUX}
      />
    </>
  );
};

const RoundData = (props: any) => {
  const handleChange = (key: string) => (e: any, data?: any) => {
    const value = data.type === "checkbox" ? data.checked : data.value;
    props.onChangeSpecimenComponent(value, props.index, key);
  };

  return (
    <Form.Group inline>
      <Form.Field></Form.Field>
      <Form.Field inline={true} width={4}>
        <Checkbox
          checked={props.item?.is_tissue}
          label={"Tissue"}
          onChange={handleChange("is_tissue")}
          style={{
            fontWeight: "bold",
            minWidth: "max-content",
            marginRight: "0.35rem",
          }}
        ></Checkbox>{" "}
        <Input
          value={props.item?.tissue}
          fluid={true}
          style={{ width: "100%" }}
          onChange={handleChange("tissue")}
          disabled={props.item?.is_tissue ? false : true}
        ></Input>
      </Form.Field>
      <Form.Field inline={true} width={4}>
        <Checkbox
          checked={props.item?.is_culture}
          label={"Culture"}
          onChange={handleChange("is_culture")}
          style={{
            fontWeight: "bold",
            minWidth: "max-content",
            marginRight: "0.35rem",
          }}
        ></Checkbox>
        <Input
          value={props.item?.culture}
          fluid={true}
          style={{ width: "100%" }}
          onChange={handleChange("culture")}
          disabled={props.item?.is_culture ? false : true}
        ></Input>
      </Form.Field>
      <Form.Field inline style={{ fontWeight: "bold", minWidth: "max-content" }}>
        <Checkbox
          checked={props.item?.is_frozen}
          label={"Frozen"}
          style={{ marginRight: "0.35rem" }}
          onChange={handleChange("is_frozen")}
        ></Checkbox>
        <Checkbox
          checked={props.item?.is_cyto_section}
          label={"Cyto_section"}
          style={{ marginRight: "0.35rem" }}
          onChange={handleChange("is_cyto_section")}
        ></Checkbox>
        <Checkbox
          checked={props.item?.is_estrogen_rec}
          label={"Estrogen rec"}
          style={{ marginRight: "0" }}
          onChange={handleChange("is_estrogen_rec")}
        ></Checkbox>
      </Form.Field>
      <Form.Field inline width={4}>
        <Checkbox
          checked={props.item?.is_other_specimen}
          label={"Other"}
          onChange={handleChange("is_other_specimen")}
          style={{
            fontWeight: "bold",
            minWidth: "max-content",
            marginRight: "0.35rem",
          }}
        ></Checkbox>
        <Input
          value={props.item?.other_specimen}
          onChange={handleChange("other_specimen")}
          disabled={props.item?.is_other_specimen ? false : true}
        ></Input>
      </Form.Field>
      <Form.Field>
        <Button
          color="red"
          icon="minus"
          size="mini"
          onClick={() => props.handlerDeleteRound(props.index)}
        ></Button>
      </Form.Field>
      <Form.Field style={{ padding: 0 }}>
        <Button
          color="blue"
          icon="plus"
          size="mini"
          onClick={() => props.handlerAddRound()}
        ></Button>
      </Form.Field>
    </Form.Group>
  );
};

CardSurgicalSafetyChecklistSignout.defaultProps = CardSurgicalSafetyChecklistSignoutInitial;

export default React.memo(CardSurgicalSafetyChecklistSignout);

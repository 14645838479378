import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Checkbox,
  Input,
  Dropdown,
  Label,
  TextArea
} from 'semantic-ui-react'
import {
  DateTextBox
} from 'react-lib/apps/common'

const CardSurgicalSafetyChecklistSignoutUX = (props: any) => {
    return(
      <div
        id="CardSurgicalSafetyChecklistSignoutUX-div-0"
        style={{ margin: "15px", padding: "20px", backgroundColor: "rgba(198, 235, 243, 0.2)" }}>
        <div
          id="CardSurgicalSafetyChecklistSignoutUX-div-1"
          style={{ paddingBottom: "5px" }}>
          
          <div
            id="CardSurgicalSafetyChecklistSignoutUX-div-157"
            style={{display: "flex", justifyContent: "space-between"}}>
            
            <label
              id="CardSurgicalSafetyChecklistSignoutUX-label-159"
              style={{ fontWeight: "bold", fontSize: "18px" }}>
              Surgical Safety Checklist - Sign out
            </label>
            <Label
              id="CardSurgicalSafetyChecklistSignoutUX-Label-160"
              style={{display: props.status? "":"none"}}>
              {props.status}
            </Label>
          </div>
          <hr
            id="CardSurgicalSafetyChecklistSignoutUX-hr-4">
          </hr>
        </div>
        <div
          id="CardSurgicalSafetyChecklistSignoutUX-div-77"
          style={{ display: "flex", paddingBottom: "5px",alignItems: "center" }}>
          
          <div
            id="CardSurgicalSafetyChecklistSignoutUX-div-78"
            style={{ fontWeight: "bold", width: "15%", margin: "5px 10px 0px 0px" }}>
            
            <label
              id="CardSurgicalSafetyChecklistSignoutUX-label-79">
              Skin Condition
            </label>
            <label
              id="CardSurgicalSafetyChecklistSignoutUX-label-80"
              style={{ color: "red" }}>
              *
            </label>
          </div>
          <Checkbox
            checked={props.surgicalSafety?.data?.is_skin_condition}
            id="CardSurgicalSafetyChecklistSignoutUX-Checkbox-81"
            label={props.surgicalSafety?.data?.is_skin_condition ? "Change" : "Unchange"}
            onChange={props.handleChangeValue("is_skin_condition")}
            style={{ marginRight: "10px" }}
            toggle={true}>
          </Checkbox>
          <Input
            disabled={props.surgicalSafety?.data?.is_skin_condition ? false : true}
            id="CardSurgicalSafetyChecklistSignoutUX-Input-82"
            onChange={props.handleChangeValue("skin_condition")}
            style={{ width: "30%" }}
            value={props.surgicalSafety?.data?.skin_condition}>
          </Input>
        </div>
        <div
          id="CardSurgicalSafetyChecklistSignoutUX-div-83"
          style={{ display: "flex", paddingBottom: "5px",alignItems: "center" }}>
          
          <label
            id="CardSurgicalSafetyChecklistSignoutUX-label-85"
            style={{ fontWeight: "bold", width: "15%", margin: "5px 10px 0px 0px" }}>
            Estimate Blood Loss
          </label>
          <Input
            id="CardSurgicalSafetyChecklistSignoutUX-Input-88"
            onChange={props.handleChangeValue("estimate_blood_loss")}
            style={{ width: "10%" }}
            value={props.surgicalSafety?.data?.estimate_blood_loss}>
          </Input>
          <label
            id="CardSurgicalSafetyChecklistSignoutUX-label-89"
            style={{ fontWeight: "bold", margin: "5px 10px 0px 10px" }}>
            cc
          </label>
        </div>
        <div
          id="CardSurgicalSafetyChecklistSignoutUX-div-90"
          style={{ display: "flex", paddingBottom: "5px", alignItems: "center" }}>
          
          <label
            id="CardSurgicalSafetyChecklistSignoutUX-label-91"
            style={{ fontWeight: "bold", width: "15%", margin: "5px 10px 0px 0px" }}>
            Blood Administered
          </label>
          <Checkbox
            checked={props.surgicalSafety?.data?.is_blood_administered}
            id="CardSurgicalSafetyChecklistSignoutUX-Checkbox-92"
            label={props.surgicalSafety?.data?.is_blood_administered ? "Yes" : "No"}
            onChange={props.handleChangeValue("is_blood_administered")}
            style={{ marginRight: "10px", minWidth: "max-content" }}
            toggle={true}>
          </Checkbox>
          <Input
            disabled={props.surgicalSafety?.data?.is_blood_administered ? false : true}
            id="CardSurgicalSafetyChecklistSignoutUX-Input-156"
            onChange={props.handleChangeValue("blood_administered")}
            style={{ width: "80%" }}
            value={props.surgicalSafety?.data?.blood_administered}>
          </Input>
        </div>
        <div
          id="CardSurgicalSafetyChecklistSignoutUX-div-93"
          style={{ display: "flex", paddingBottom: "5px" ,alignItems: "center"}}>
          
          <div
            id="CardSurgicalSafetyChecklistSignoutUX-div-94"
            style={{ fontWeight: "bold", width: "15%", margin: "5px 10px 0px 0px" }}>
            
            <label
              id="CardSurgicalSafetyChecklistSignoutUX-label-95">
              Drain
            </label>
            <label
              id="CardSurgicalSafetyChecklistSignoutUX-label-96"
              style={{ color: "red" }}>
              *
            </label>
          </div>
          <Checkbox
            checked={props.surgicalSafety?.data?.is_drain}
            id="CardSurgicalSafetyChecklistSignoutUX-Checkbox-97"
            label={props.surgicalSafety?.data?.is_drain ? "Yes" : "No"}
            onChange={props.handleChangeValue("is_drain")}
            style={{ marginRight: "10px", minWidth: "max-content" }}
            toggle={true}>
          </Checkbox>
          <Dropdown
            allowAdditions={true}
            clearable={true}
            disabled={props.surgicalSafety?.data?.is_drain ? false : true}
            id="CardSurgicalSafetyChecklistSignoutUX-Dropdown-98"
            multiple={true}
            onAddItem={props.handleAddOptions}
            onChange={props.handleChangeValue("drain")}
            options={props.options?.orDrainDetailOption}
            search={true}
            selection={true}
            style={{ width: "80%" }}
            value={props.surgicalSafety?.data?.drain}>
          </Dropdown>
        </div>
        <div
          id="CardSurgicalSafetyChecklistSignoutUX-div-99"
          style={{ display: "flex", paddingBottom: "5px" ,alignItems: "center"}}>
          
          <div
            id="CardSurgicalSafetyChecklistSignoutUX-div-100"
            style={{ fontWeight: "bold", width: "15%", margin: "5px 10px 0px 0px" }}>
            
            <label
              id="CardSurgicalSafetyChecklistSignoutUX-label-101">
              Incision Closure
            </label>
            <label
              id="CardSurgicalSafetyChecklistSignoutUX-label-102"
              style={{ color: "red" }}>
              *
            </label>
          </div>
          <Checkbox
            checked={props.surgicalSafety?.data?.is_incision_closure}
            id="CardSurgicalSafetyChecklistSignoutUX-Checkbox-103"
            label={props.surgicalSafety?.data?.is_incision_closure ? "Yes" : "No"}
            onChange={props.handleChangeValue("is_incision_closure")}
            style={{  marginRight: "10px" , minWidth: "max-content"}}
            toggle={true}>
          </Checkbox>
          <Dropdown
            allowAdditions={true}
            clearable={true}
            disabled={props.surgicalSafety?.data?.is_incision_closure ? false : true}
            id="CardSurgicalSafetyChecklistSignoutUX-Dropdown-104"
            multiple={true}
            onAddItem={props.handleAddOptions}
            onChange={props.handleChangeValue("incision_closure")}
            options={props.options?.orICDetailOption}
            search={true}
            selection={true}
            style={{ width: "80%" }}
            value={props.surgicalSafety?.data?.incision_closure}>
          </Dropdown>
        </div>
        <div
          id="CardSurgicalSafetyChecklistSignoutUX-div-105"
          style={{ display: "flex", paddingBottom: "5px",alignItems: "center" }}>
          
          <label
            id="CardSurgicalSafetyChecklistSignoutUX-label-106"
            style={{ fontWeight: "bold", width: "15%", margin: "5px 10px 0px 0px" }}>
            Specimen
          </label>
          <Checkbox
            checked={props.surgicalSafety?.data?.no_specimen}
            id="CardSurgicalSafetyChecklistSignoutUX-Checkbox-107"
            label={props.surgicalSafety?.data?.no_specimen ? "Yes" : "No"}
            onChange={props.handleChangeValue("no_specimen")}
            style={{  marginRight: "10px" }}>
          </Checkbox>
        </div>
        <div
          id="CardSurgicalSafetyChecklistSignoutUX-div-108"
          style={{ display: "flex", padding: "0px 0px 5px 0px" }}>
          {props.specimenComponent}
        </div>
        <div
          id="CardSurgicalSafetyChecklistSignoutUX-div-109"
          style={{ backgroundColor: "#FDF8DD",  padding: "20px", margin: "15px 0px 30px" }}>
          
          <div
            id="CardSurgicalSafetyChecklistSignoutUX-div-112"
            style={{ paddingBottom: "5px", height: "30px" }}>
            
            <Label
              id="CardSurgicalSafetyChecklistSignoutUX-Label-110"
              style={{ float: "right" }}>
              Sign out
            </Label>
          </div>
          <div
            id="CardSurgicalSafetyChecklistSignoutUX-div-130"
            style={{ display: "flex", paddingBottom: "10px"}}>
            
            <label
              id="CardSurgicalSafetyChecklistSignoutUX-label-131"
              style={{ width: "45%", margin: "5px 10px 0px 0px",  fontWeight: "bold" }}>
              The name of the procedure
            </label>
            <Checkbox
              checked={props.surgicalSafety?.data?.name_procedure}
              id="CardSurgicalSafetyChecklistSignoutUX-Checkbox-132"
              label={props.surgicalSafety?.data?.name_procedure ? "Yes" : "No"}
              onChange={props.handleChangeValue("name_procedure")}
              toggle={true}>
            </Checkbox>
          </div>
          <div
            id="CardSurgicalSafetyChecklistSignoutUX-div-113"
            style={{ display: "flex", paddingBottom: "10px" }}>
            
            <div
              id="CardSurgicalSafetyChecklistSignoutUX-div-116"
              style={{ width: "45%", margin: "5px 10px 0px 0px" ,  fontWeight: "bold"}}>
              
              <label
                id="CardSurgicalSafetyChecklistSignoutUX-label-117">
                Completion of instrument, sponge and needle counts
              </label>
              <label
                id="CardSurgicalSafetyChecklistSignoutUX-label-118"
                style={{ color: "red" }}>
                *
              </label>
            </div>
            <Checkbox
              checked={props.surgicalSafety?.data?.completion}
              id="CardSurgicalSafetyChecklistSignoutUX-Checkbox-119"
              label={props.surgicalSafety?.data?.completion ? "Yes" : "No"}
              onChange={props.handleChangeValue("completion")}
              toggle={true}>
            </Checkbox>
          </div>
          <div
            id="CardSurgicalSafetyChecklistSignoutUX-div-120"
            style={{ display: "flex", paddingBottom: "10px"}}>
            
            <div
              id="CardSurgicalSafetyChecklistSignoutUX-div-121"
              style={{ width: "45%", margin: "5px 10px 0px 0px" ,  fontWeight: "bold"}}>
              
              <label
                id="CardSurgicalSafetyChecklistSignoutUX-label-122">
                Specimen labelling
              </label>
              <label
                id="CardSurgicalSafetyChecklistSignoutUX-label-123"
                style={{ color: "red" }}>
                *
              </label>
            </div>
            <Checkbox
              checked={props.surgicalSafety?.data?.specimen_labelling}
              id="CardSurgicalSafetyChecklistSignoutUX-Checkbox-124"
              label={props.surgicalSafety?.data?.specimen_labelling ? "Yes" : "No"}
              onChange={props.handleChangeValue("specimen_labelling")}
              toggle={true}>
            </Checkbox>
          </div>
          <div
            id="CardSurgicalSafetyChecklistSignoutUX-div-125"
            style={{ display: "flex", paddingBottom: "10px" }}>
            
            <div
              id="CardSurgicalSafetyChecklistSignoutUX-div-126"
              style={{ width: "45%", margin: "5px 10px 0px 0px",  fontWeight: "bold" }}>
              
              <label
                id="CardSurgicalSafetyChecklistSignoutUX-label-127">
                Whether there are any equipment problems to be addressed
              </label>
              <label
                id="CardSurgicalSafetyChecklistSignoutUX-label-128"
                style={{ color: "red" }}>
                *
              </label>
            </div>
            <Checkbox
              checked={props.surgicalSafety?.data?.equipment_problems}
              id="CardSurgicalSafetyChecklistSignoutUX-Checkbox-129"
              label={props.surgicalSafety?.data?.equipment_problems ? "Yes" : "No"}
              onChange={props.handleChangeValue("equipment_problems")}
              toggle={true}>
            </Checkbox>
          </div>
          <div
            id="CardSurgicalSafetyChecklistSignoutUX-div-135"
            style={{ display: "flex", paddingBottom: "10px", fontWeight: "bold" }}>
            
            <label
              id="CardSurgicalSafetyChecklistSignoutUX-label-136"
              style={{ width: "45%", margin: "5px 10px 0px 0px" ,  fontWeight: "bold"}}>
              To Surgeon, Anesthesiologists and Nurse:
            </label>
          </div>
          <div
            id="CardSurgicalSafetyChecklistSignoutUX-div-137"
            style={{ display: "flex", paddingBottom: "10px" }}>
            
            <div
              id="CardSurgicalSafetyChecklistSignoutUX-div-138"
              style={{ width: "45%", margin: "5px 10px 0px 0px",  fontWeight: "bold" }}>
              
              <label
                id="CardSurgicalSafetyChecklistSignoutUX-label-139">
                The key concerns for recovery and manage of this patient
              </label>
              <label
                id="CardSurgicalSafetyChecklistSignoutUX-label-140"
                style={{ color: "red" }}>
                *
              </label>
            </div>
            <Checkbox
              checked={props.surgicalSafety?.data?.key_concerns}
              id="CardSurgicalSafetyChecklistSignoutUX-Checkbox-141"
              label={props.surgicalSafety?.data?.key_concerns ? "Yes" : "No"}
              onChange={props.handleChangeValue("key_concerns")}
              toggle={true}>
            </Checkbox>
          </div>
          <div
            id="CardSurgicalSafetyChecklistSignoutUX-div-142"
            style={{ display: "flex", paddingBottom: "10px", }}>
            
            <Checkbox
              checked={props.surgicalSafety?.data?.respiration}
              id="CardSurgicalSafetyChecklistSignoutUX-Checkbox-143"
              label="Respiration"
              onChange={props.handleChangeValue("respiration")}
              style={{ marginRight: "50px" }}>
            </Checkbox>
            <Checkbox
              checked={props.surgicalSafety?.data?.cardiovaacular}
              id="CardSurgicalSafetyChecklistSignoutUX-Checkbox-144"
              label="Cardiovaacular"
              onChange={props.handleChangeValue("cardiovaacular")}
              style={{ marginRight: "50px" }}>
            </Checkbox>
            <Checkbox
              checked={props.surgicalSafety?.data?.metabolism}
              id="CardSurgicalSafetyChecklistSignoutUX-Checkbox-145"
              label="Metabolism"
              onChange={props.handleChangeValue("metabolism")}
              style={{ marginRight: "50px" }}>
            </Checkbox>
            <Checkbox
              checked={props.surgicalSafety?.data?.neuro_sign}
              id="CardSurgicalSafetyChecklistSignoutUX-Checkbox-146"
              label="Neuro sign"
              onChange={props.handleChangeValue("neuro_sign")}
              style={{ marginRight: "50px" }}>
            </Checkbox>
            <Checkbox
              checked={props.surgicalSafety?.data?.other}
              id="CardSurgicalSafetyChecklistSignoutUX-Checkbox-147"
              label="other"
              onChange={props.handleChangeValue("other")}>
            </Checkbox>
          </div>
        </div>
        <div
          className="ui form"
          id="CardSurgicalSafetyChecklistSignoutUX-div-148"
          style={{ display: "flex", paddingBottom: "5px" }}>
          {}
          <label
            id="CardSurgicalSafetyChecklistSignoutUX-label-149"
            style={{ fontWeight: "bold", marginRight: "10px" }}>
            Remark
          </label>
          <TextArea
            id="CardSurgicalSafetyChecklistSignoutUX-TextArea-150"
            onChange={props.handleChangeValue("remark")}
            rows={4}
            style={{ width: "60%" }}
            value={props.remark}>
          </TextArea>
          <div
            id="CardSurgicalSafetyChecklistSignoutUX-div-151"
            style={{ fontWeight: "bold", margin: "0px 10px" }}>
            
            <label
              id="CardSurgicalSafetyChecklistSignoutUX-label-152">
              Closure
            </label>
            <label
              id="CardSurgicalSafetyChecklistSignoutUX-label-153"
              style={{ color: "red" }}>
              *
            </label>
          </div>
          <DateTextBox
            id="CardSurgicalSafetyChecklistSignoutUX-DateTextBox-154"
            onChange={props.handleChangeDate("closureDate")}
            value={props.closureDate}>
          </DateTextBox>
          <div
            id="CardSurgicalSafetyChecklistSignoutUX-div-165">
            {props.timeTextBox}
          </div>
        </div>
        <div
          id="CardSurgicalSafetyChecklistSignoutUX-div-59"
          style={{ height: "55px", paddingTop: "15px" }}>
          
          <div
            id="CardSurgicalSafetyChecklistSignoutUX-div-60"
            style={{ display: "flex", justifyContent: "flex-end" }}>
            
            <div
              id="CardSurgicalSafetyChecklistSignoutUX-div-161">
              {props.buttonSave}
            </div>
            <div
              id="CardSurgicalSafetyChecklistSignoutUX-div-163"
              style={{ margin: "0px 10px" }}>
              {props.buttonConfirm}
            </div>
            <div
              id="CardSurgicalSafetyChecklistSignoutUX-div-164">
              {props.buttonUnconfirm}
            </div>
          </div>
        </div>
      </div>
    )
}


export default CardSurgicalSafetyChecklistSignoutUX

export const screenPropsDefault = {}

/* Date Time : Thu Feb 20 2025 17:15:50 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "id": {
          "type": "value",
          "value": "CardSurgicalSafetyChecklistSignoutUX-div-0"
        },
        "style": {
          "type": "code",
          "value": "{ margin: \"15px\", padding: \"20px\", backgroundColor: \"rgba(198, 235, 243, 0.2)\" }"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardSurgicalSafetyChecklistSignoutUX-div-1"
        },
        "style": {
          "type": "code",
          "value": "{ paddingBottom: \"5px\" }"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 4,
      "name": "hr",
      "parent": 1,
      "props": {
        "id": {
          "type": "value",
          "value": "CardSurgicalSafetyChecklistSignoutUX-hr-4"
        }
      },
      "seq": 157,
      "void": true
    },
    {
      "from": null,
      "id": 59,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardSurgicalSafetyChecklistSignoutUX-div-59"
        },
        "style": {
          "type": "code",
          "value": "{ height: \"55px\", paddingTop: \"15px\" }"
        }
      },
      "seq": 148,
      "void": false
    },
    {
      "from": null,
      "id": 60,
      "name": "div",
      "parent": 59,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardSurgicalSafetyChecklistSignoutUX-div-60"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", justifyContent: \"flex-end\" }"
        }
      },
      "seq": 60,
      "void": false
    },
    {
      "from": null,
      "id": 77,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardSurgicalSafetyChecklistSignoutUX-div-77"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", paddingBottom: \"5px\",alignItems: \"center\" }"
        }
      },
      "seq": 71,
      "void": false
    },
    {
      "from": null,
      "id": 78,
      "name": "div",
      "parent": 77,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardSurgicalSafetyChecklistSignoutUX-div-78"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\", width: \"15%\", margin: \"5px 10px 0px 0px\" }"
        }
      },
      "seq": 78,
      "void": false
    },
    {
      "from": null,
      "id": 79,
      "name": "label",
      "parent": 78,
      "props": {
        "children": {
          "type": "value",
          "value": "Skin Condition"
        },
        "id": {
          "type": "value",
          "value": "CardSurgicalSafetyChecklistSignoutUX-label-79"
        },
        "style": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 79,
      "void": false
    },
    {
      "from": null,
      "id": 80,
      "name": "label",
      "parent": 78,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "id": {
          "type": "value",
          "value": "CardSurgicalSafetyChecklistSignoutUX-label-80"
        },
        "style": {
          "type": "code",
          "value": "{ color: \"red\" }"
        }
      },
      "seq": 80,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 81,
      "name": "Checkbox",
      "parent": 77,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.surgicalSafety?.data?.is_skin_condition"
        },
        "id": {
          "type": "value",
          "value": "CardSurgicalSafetyChecklistSignoutUX-Checkbox-81"
        },
        "label": {
          "type": "code",
          "value": "props.surgicalSafety?.data?.is_skin_condition ? \"Change\" : \"Unchange\""
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"is_skin_condition\")"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"10px\" }"
        },
        "toggle": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 81,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 82,
      "name": "Input",
      "parent": 77,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.surgicalSafety?.data?.is_skin_condition ? false : true"
        },
        "id": {
          "type": "value",
          "value": "CardSurgicalSafetyChecklistSignoutUX-Input-82"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"skin_condition\")"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"30%\" }"
        },
        "value": {
          "type": "code",
          "value": "props.surgicalSafety?.data?.skin_condition"
        }
      },
      "seq": 82,
      "void": false
    },
    {
      "from": null,
      "id": 83,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardSurgicalSafetyChecklistSignoutUX-div-83"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", paddingBottom: \"5px\",alignItems: \"center\" }"
        }
      },
      "seq": 83,
      "void": false
    },
    {
      "from": null,
      "id": 85,
      "name": "label",
      "parent": 83,
      "props": {
        "children": {
          "type": "value",
          "value": "Estimate Blood Loss"
        },
        "id": {
          "type": "value",
          "value": "CardSurgicalSafetyChecklistSignoutUX-label-85"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\", width: \"15%\", margin: \"5px 10px 0px 0px\" }"
        }
      },
      "seq": 85,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 88,
      "name": "Input",
      "parent": 83,
      "props": {
        "id": {
          "type": "value",
          "value": "CardSurgicalSafetyChecklistSignoutUX-Input-88"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"estimate_blood_loss\")"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"10%\" }"
        },
        "value": {
          "type": "code",
          "value": "props.surgicalSafety?.data?.estimate_blood_loss"
        }
      },
      "seq": 88,
      "void": false
    },
    {
      "from": null,
      "id": 89,
      "name": "label",
      "parent": 83,
      "props": {
        "children": {
          "type": "value",
          "value": "cc"
        },
        "id": {
          "type": "value",
          "value": "CardSurgicalSafetyChecklistSignoutUX-label-89"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\", margin: \"5px 10px 0px 10px\" }"
        }
      },
      "seq": 89,
      "void": false
    },
    {
      "from": null,
      "id": 90,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardSurgicalSafetyChecklistSignoutUX-div-90"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", paddingBottom: \"5px\", alignItems: \"center\" }"
        }
      },
      "seq": 90,
      "void": false
    },
    {
      "from": null,
      "id": 91,
      "name": "label",
      "parent": 90,
      "props": {
        "children": {
          "type": "value",
          "value": "Blood Administered"
        },
        "id": {
          "type": "value",
          "value": "CardSurgicalSafetyChecklistSignoutUX-label-91"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\", width: \"15%\", margin: \"5px 10px 0px 0px\" }"
        }
      },
      "seq": 91,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 92,
      "name": "Checkbox",
      "parent": 90,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.surgicalSafety?.data?.is_blood_administered"
        },
        "id": {
          "type": "value",
          "value": "CardSurgicalSafetyChecklistSignoutUX-Checkbox-92"
        },
        "label": {
          "type": "code",
          "value": "props.surgicalSafety?.data?.is_blood_administered ? \"Yes\" : \"No\""
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"is_blood_administered\")"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"10px\", minWidth: \"max-content\" }"
        },
        "toggle": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 92,
      "void": false
    },
    {
      "from": null,
      "id": 93,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardSurgicalSafetyChecklistSignoutUX-div-93"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", paddingBottom: \"5px\" ,alignItems: \"center\"}"
        }
      },
      "seq": 93,
      "void": false
    },
    {
      "from": null,
      "id": 94,
      "name": "div",
      "parent": 93,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardSurgicalSafetyChecklistSignoutUX-div-94"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\", width: \"15%\", margin: \"5px 10px 0px 0px\" }"
        }
      },
      "seq": 94,
      "void": false
    },
    {
      "from": null,
      "id": 95,
      "name": "label",
      "parent": 94,
      "props": {
        "children": {
          "type": "value",
          "value": "Drain"
        },
        "id": {
          "type": "value",
          "value": "CardSurgicalSafetyChecklistSignoutUX-label-95"
        },
        "style": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 95,
      "void": false
    },
    {
      "from": null,
      "id": 96,
      "name": "label",
      "parent": 94,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "id": {
          "type": "value",
          "value": "CardSurgicalSafetyChecklistSignoutUX-label-96"
        },
        "style": {
          "type": "code",
          "value": "{ color: \"red\" }"
        }
      },
      "seq": 96,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 97,
      "name": "Checkbox",
      "parent": 93,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.surgicalSafety?.data?.is_drain"
        },
        "id": {
          "type": "value",
          "value": "CardSurgicalSafetyChecklistSignoutUX-Checkbox-97"
        },
        "label": {
          "type": "code",
          "value": "props.surgicalSafety?.data?.is_drain ? \"Yes\" : \"No\""
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"is_drain\")"
        },
        "onClick": {
          "type": "code",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"10px\", minWidth: \"max-content\" }"
        },
        "toggle": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 97,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 98,
      "name": "Dropdown",
      "parent": 93,
      "props": {
        "allowAdditions": {
          "type": "code",
          "value": "true"
        },
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "disabled": {
          "type": "code",
          "value": "props.surgicalSafety?.data?.is_drain ? false : true"
        },
        "fluid": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardSurgicalSafetyChecklistSignoutUX-Dropdown-98"
        },
        "multiple": {
          "type": "code",
          "value": "true"
        },
        "onAddItem": {
          "type": "code",
          "value": "props.handleAddOptions"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"drain\")"
        },
        "options": {
          "type": "code",
          "value": "props.options?.orDrainDetailOption"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"80%\" }"
        },
        "value": {
          "type": "code",
          "value": "props.surgicalSafety?.data?.drain"
        }
      },
      "seq": 98,
      "void": false
    },
    {
      "from": null,
      "id": 99,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardSurgicalSafetyChecklistSignoutUX-div-99"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", paddingBottom: \"5px\" ,alignItems: \"center\"}"
        }
      },
      "seq": 99,
      "void": false
    },
    {
      "from": null,
      "id": 100,
      "name": "div",
      "parent": 99,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardSurgicalSafetyChecklistSignoutUX-div-100"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\", width: \"15%\", margin: \"5px 10px 0px 0px\" }"
        }
      },
      "seq": 100,
      "void": false
    },
    {
      "from": null,
      "id": 101,
      "name": "label",
      "parent": 100,
      "props": {
        "children": {
          "type": "value",
          "value": "Incision Closure"
        },
        "id": {
          "type": "value",
          "value": "CardSurgicalSafetyChecklistSignoutUX-label-101"
        },
        "style": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 101,
      "void": false
    },
    {
      "from": null,
      "id": 102,
      "name": "label",
      "parent": 100,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "id": {
          "type": "value",
          "value": "CardSurgicalSafetyChecklistSignoutUX-label-102"
        },
        "style": {
          "type": "code",
          "value": "{ color: \"red\" }"
        }
      },
      "seq": 102,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 103,
      "name": "Checkbox",
      "parent": 99,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.surgicalSafety?.data?.is_incision_closure"
        },
        "id": {
          "type": "value",
          "value": "CardSurgicalSafetyChecklistSignoutUX-Checkbox-103"
        },
        "label": {
          "type": "code",
          "value": "props.surgicalSafety?.data?.is_incision_closure ? \"Yes\" : \"No\""
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"is_incision_closure\")"
        },
        "style": {
          "type": "code",
          "value": "{  marginRight: \"10px\" , minWidth: \"max-content\"}"
        },
        "toggle": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 103,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 104,
      "name": "Dropdown",
      "parent": 99,
      "props": {
        "allowAdditions": {
          "type": "code",
          "value": "true"
        },
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "disabled": {
          "type": "code",
          "value": "props.surgicalSafety?.data?.is_incision_closure ? false : true"
        },
        "fluid": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardSurgicalSafetyChecklistSignoutUX-Dropdown-104"
        },
        "multiple": {
          "type": "code",
          "value": "true"
        },
        "onAddItem": {
          "type": "code",
          "value": "props.handleAddOptions"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"incision_closure\")"
        },
        "options": {
          "type": "code",
          "value": "props.options?.orICDetailOption"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"80%\" }"
        },
        "value": {
          "type": "code",
          "value": "props.surgicalSafety?.data?.incision_closure"
        }
      },
      "seq": 104,
      "void": false
    },
    {
      "from": null,
      "id": 105,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardSurgicalSafetyChecklistSignoutUX-div-105"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", paddingBottom: \"5px\",alignItems: \"center\" }"
        }
      },
      "seq": 105,
      "void": false
    },
    {
      "from": null,
      "id": 106,
      "name": "label",
      "parent": 105,
      "props": {
        "children": {
          "type": "value",
          "value": "Specimen"
        },
        "id": {
          "type": "value",
          "value": "CardSurgicalSafetyChecklistSignoutUX-label-106"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\", width: \"15%\", margin: \"5px 10px 0px 0px\" }"
        }
      },
      "seq": 106,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 107,
      "name": "Checkbox",
      "parent": 105,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.surgicalSafety?.data?.no_specimen"
        },
        "id": {
          "type": "value",
          "value": "CardSurgicalSafetyChecklistSignoutUX-Checkbox-107"
        },
        "label": {
          "type": "code",
          "value": "props.surgicalSafety?.data?.no_specimen ? \"Yes\" : \"No\""
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"no_specimen\")"
        },
        "style": {
          "type": "code",
          "value": "{  marginRight: \"10px\" }"
        },
        "toggle": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 107,
      "void": false
    },
    {
      "from": null,
      "id": 108,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "code",
          "value": "props.specimenComponent"
        },
        "id": {
          "type": "value",
          "value": "CardSurgicalSafetyChecklistSignoutUX-div-108"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", padding: \"0px 0px 5px 0px\" }"
        }
      },
      "seq": 108,
      "void": false
    },
    {
      "from": null,
      "id": 109,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardSurgicalSafetyChecklistSignoutUX-div-109"
        },
        "style": {
          "type": "code",
          "value": "{ backgroundColor: \"#FDF8DD\",  padding: \"20px\", margin: \"15px 0px 30px\" }"
        }
      },
      "seq": 109,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 110,
      "name": "Label",
      "parent": 112,
      "props": {
        "children": {
          "type": "value",
          "value": "Sign out"
        },
        "id": {
          "type": "value",
          "value": "CardSurgicalSafetyChecklistSignoutUX-Label-110"
        },
        "style": {
          "type": "code",
          "value": "{ float: \"right\" }"
        }
      },
      "seq": 134,
      "void": false
    },
    {
      "from": null,
      "id": 112,
      "name": "div",
      "parent": 109,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardSurgicalSafetyChecklistSignoutUX-div-112"
        },
        "style": {
          "type": "code",
          "value": "{ paddingBottom: \"5px\", height: \"30px\" }"
        }
      },
      "seq": 112,
      "void": false
    },
    {
      "from": null,
      "id": 113,
      "name": "div",
      "parent": 109,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardSurgicalSafetyChecklistSignoutUX-div-113"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", paddingBottom: \"10px\" }"
        }
      },
      "seq": 120,
      "void": false
    },
    {
      "from": null,
      "id": 116,
      "name": "div",
      "parent": 113,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardSurgicalSafetyChecklistSignoutUX-div-116"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"45%\", margin: \"5px 10px 0px 0px\" ,  fontWeight: \"bold\"}"
        }
      },
      "seq": 116,
      "void": false
    },
    {
      "from": null,
      "id": 117,
      "name": "label",
      "parent": 116,
      "props": {
        "children": {
          "type": "value",
          "value": "Completion of instrument, sponge and needle counts"
        },
        "id": {
          "type": "value",
          "value": "CardSurgicalSafetyChecklistSignoutUX-label-117"
        },
        "style": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 117,
      "void": false
    },
    {
      "from": null,
      "id": 118,
      "name": "label",
      "parent": 116,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "id": {
          "type": "value",
          "value": "CardSurgicalSafetyChecklistSignoutUX-label-118"
        },
        "style": {
          "type": "code",
          "value": "{ color: \"red\" }"
        }
      },
      "seq": 118,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 119,
      "name": "Checkbox",
      "parent": 113,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.surgicalSafety?.data?.completion"
        },
        "id": {
          "type": "value",
          "value": "CardSurgicalSafetyChecklistSignoutUX-Checkbox-119"
        },
        "label": {
          "type": "code",
          "value": "props.surgicalSafety?.data?.completion ? \"Yes\" : \"No\""
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"completion\")"
        },
        "style": {
          "type": "code",
          "value": ""
        },
        "toggle": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 119,
      "void": false
    },
    {
      "from": null,
      "id": 120,
      "name": "div",
      "parent": 109,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardSurgicalSafetyChecklistSignoutUX-div-120"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", paddingBottom: \"10px\"}"
        }
      },
      "seq": 125,
      "void": false
    },
    {
      "from": null,
      "id": 121,
      "name": "div",
      "parent": 120,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardSurgicalSafetyChecklistSignoutUX-div-121"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"45%\", margin: \"5px 10px 0px 0px\" ,  fontWeight: \"bold\"}"
        }
      },
      "seq": 121,
      "void": false
    },
    {
      "from": null,
      "id": 122,
      "name": "label",
      "parent": 121,
      "props": {
        "children": {
          "type": "value",
          "value": "Specimen labelling"
        },
        "id": {
          "type": "value",
          "value": "CardSurgicalSafetyChecklistSignoutUX-label-122"
        },
        "style": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 122,
      "void": false
    },
    {
      "from": null,
      "id": 123,
      "name": "label",
      "parent": 121,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "id": {
          "type": "value",
          "value": "CardSurgicalSafetyChecklistSignoutUX-label-123"
        },
        "style": {
          "type": "code",
          "value": "{ color: \"red\" }"
        }
      },
      "seq": 123,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 124,
      "name": "Checkbox",
      "parent": 120,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.surgicalSafety?.data?.specimen_labelling"
        },
        "id": {
          "type": "value",
          "value": "CardSurgicalSafetyChecklistSignoutUX-Checkbox-124"
        },
        "label": {
          "type": "code",
          "value": "props.surgicalSafety?.data?.specimen_labelling ? \"Yes\" : \"No\""
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"specimen_labelling\")"
        },
        "style": {
          "type": "code",
          "value": ""
        },
        "toggle": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 124,
      "void": false
    },
    {
      "from": null,
      "id": 125,
      "name": "div",
      "parent": 109,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardSurgicalSafetyChecklistSignoutUX-div-125"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", paddingBottom: \"10px\" }"
        }
      },
      "seq": 130,
      "void": false
    },
    {
      "from": null,
      "id": 126,
      "name": "div",
      "parent": 125,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardSurgicalSafetyChecklistSignoutUX-div-126"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"45%\", margin: \"5px 10px 0px 0px\",  fontWeight: \"bold\" }"
        }
      },
      "seq": 126,
      "void": false
    },
    {
      "from": null,
      "id": 127,
      "name": "label",
      "parent": 126,
      "props": {
        "children": {
          "type": "value",
          "value": "Whether there are any equipment problems to be addressed"
        },
        "id": {
          "type": "value",
          "value": "CardSurgicalSafetyChecklistSignoutUX-label-127"
        },
        "style": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 127,
      "void": false
    },
    {
      "from": null,
      "id": 128,
      "name": "label",
      "parent": 126,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "id": {
          "type": "value",
          "value": "CardSurgicalSafetyChecklistSignoutUX-label-128"
        },
        "style": {
          "type": "code",
          "value": "{ color: \"red\" }"
        }
      },
      "seq": 128,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 129,
      "name": "Checkbox",
      "parent": 125,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.surgicalSafety?.data?.equipment_problems"
        },
        "id": {
          "type": "value",
          "value": "CardSurgicalSafetyChecklistSignoutUX-Checkbox-129"
        },
        "label": {
          "type": "code",
          "value": "props.surgicalSafety?.data?.equipment_problems ? \"Yes\" : \"No\""
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"equipment_problems\")"
        },
        "style": {
          "type": "code",
          "value": ""
        },
        "toggle": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 129,
      "void": false
    },
    {
      "from": null,
      "id": 130,
      "name": "div",
      "parent": 109,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardSurgicalSafetyChecklistSignoutUX-div-130"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", paddingBottom: \"10px\"}"
        }
      },
      "seq": 113,
      "void": false
    },
    {
      "from": null,
      "id": 131,
      "name": "label",
      "parent": 130,
      "props": {
        "children": {
          "type": "value",
          "value": "The name of the procedure"
        },
        "id": {
          "type": "value",
          "value": "CardSurgicalSafetyChecklistSignoutUX-label-131"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"45%\", margin: \"5px 10px 0px 0px\",  fontWeight: \"bold\" }"
        }
      },
      "seq": 131,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 132,
      "name": "Checkbox",
      "parent": 130,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.surgicalSafety?.data?.name_procedure"
        },
        "id": {
          "type": "value",
          "value": "CardSurgicalSafetyChecklistSignoutUX-Checkbox-132"
        },
        "label": {
          "type": "code",
          "value": "props.surgicalSafety?.data?.name_procedure ? \"Yes\" : \"No\""
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"name_procedure\")"
        },
        "style": {
          "type": "code",
          "value": ""
        },
        "toggle": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 132,
      "void": false
    },
    {
      "from": null,
      "id": 135,
      "name": "div",
      "parent": 109,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardSurgicalSafetyChecklistSignoutUX-div-135"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", paddingBottom: \"10px\", fontWeight: \"bold\" }"
        }
      },
      "seq": 135,
      "void": false
    },
    {
      "from": null,
      "id": 136,
      "name": "label",
      "parent": 135,
      "props": {
        "children": {
          "type": "value",
          "value": "To Surgeon, Anesthesiologists and Nurse:"
        },
        "id": {
          "type": "value",
          "value": "CardSurgicalSafetyChecklistSignoutUX-label-136"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"45%\", margin: \"5px 10px 0px 0px\" ,  fontWeight: \"bold\"}"
        }
      },
      "seq": 136,
      "void": false
    },
    {
      "from": null,
      "id": 137,
      "name": "div",
      "parent": 109,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardSurgicalSafetyChecklistSignoutUX-div-137"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", paddingBottom: \"10px\" }"
        }
      },
      "seq": 137,
      "void": false
    },
    {
      "from": null,
      "id": 138,
      "name": "div",
      "parent": 137,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardSurgicalSafetyChecklistSignoutUX-div-138"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"45%\", margin: \"5px 10px 0px 0px\",  fontWeight: \"bold\" }"
        }
      },
      "seq": 138,
      "void": false
    },
    {
      "from": null,
      "id": 139,
      "name": "label",
      "parent": 138,
      "props": {
        "children": {
          "type": "value",
          "value": "The key concerns for recovery and manage of this patient"
        },
        "id": {
          "type": "value",
          "value": "CardSurgicalSafetyChecklistSignoutUX-label-139"
        },
        "style": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 139,
      "void": false
    },
    {
      "from": null,
      "id": 140,
      "name": "label",
      "parent": 138,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "id": {
          "type": "value",
          "value": "CardSurgicalSafetyChecklistSignoutUX-label-140"
        },
        "style": {
          "type": "code",
          "value": "{ color: \"red\" }"
        }
      },
      "seq": 140,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 141,
      "name": "Checkbox",
      "parent": 137,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.surgicalSafety?.data?.key_concerns"
        },
        "id": {
          "type": "value",
          "value": "CardSurgicalSafetyChecklistSignoutUX-Checkbox-141"
        },
        "label": {
          "type": "code",
          "value": "props.surgicalSafety?.data?.key_concerns ? \"Yes\" : \"No\""
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"key_concerns\")"
        },
        "style": {
          "type": "code",
          "value": ""
        },
        "toggle": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 141,
      "void": false
    },
    {
      "from": null,
      "id": 142,
      "name": "div",
      "parent": 109,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardSurgicalSafetyChecklistSignoutUX-div-142"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", paddingBottom: \"10px\", }"
        }
      },
      "seq": 142,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 143,
      "name": "Checkbox",
      "parent": 142,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.surgicalSafety?.data?.respiration"
        },
        "id": {
          "type": "value",
          "value": "CardSurgicalSafetyChecklistSignoutUX-Checkbox-143"
        },
        "label": {
          "type": "value",
          "value": "Respiration"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"respiration\")"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"50px\" }"
        }
      },
      "seq": 143,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 144,
      "name": "Checkbox",
      "parent": 142,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.surgicalSafety?.data?.cardiovaacular"
        },
        "id": {
          "type": "value",
          "value": "CardSurgicalSafetyChecklistSignoutUX-Checkbox-144"
        },
        "label": {
          "type": "value",
          "value": "Cardiovaacular"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"cardiovaacular\")"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"50px\" }"
        }
      },
      "seq": 144,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 145,
      "name": "Checkbox",
      "parent": 142,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.surgicalSafety?.data?.metabolism"
        },
        "id": {
          "type": "value",
          "value": "CardSurgicalSafetyChecklistSignoutUX-Checkbox-145"
        },
        "label": {
          "type": "value",
          "value": "Metabolism"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"metabolism\")"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"50px\" }"
        }
      },
      "seq": 145,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 146,
      "name": "Checkbox",
      "parent": 142,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.surgicalSafety?.data?.neuro_sign"
        },
        "id": {
          "type": "value",
          "value": "CardSurgicalSafetyChecklistSignoutUX-Checkbox-146"
        },
        "label": {
          "type": "value",
          "value": "Neuro sign"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"neuro_sign\")"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"50px\" }"
        }
      },
      "seq": 146,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 147,
      "name": "Checkbox",
      "parent": 142,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.surgicalSafety?.data?.other"
        },
        "id": {
          "type": "value",
          "value": "CardSurgicalSafetyChecklistSignoutUX-Checkbox-147"
        },
        "label": {
          "type": "value",
          "value": "other"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"other\")"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 147,
      "void": false
    },
    {
      "from": null,
      "id": 148,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "code",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "id": {
          "type": "value",
          "value": "CardSurgicalSafetyChecklistSignoutUX-div-148"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", paddingBottom: \"5px\" }"
        }
      },
      "seq": 122,
      "void": false
    },
    {
      "from": null,
      "id": 149,
      "name": "label",
      "parent": 148,
      "props": {
        "children": {
          "type": "value",
          "value": "Remark"
        },
        "id": {
          "type": "value",
          "value": "CardSurgicalSafetyChecklistSignoutUX-label-149"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\", marginRight: \"10px\" }"
        }
      },
      "seq": 149,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 150,
      "name": "TextArea",
      "parent": 148,
      "props": {
        "id": {
          "type": "value",
          "value": "CardSurgicalSafetyChecklistSignoutUX-TextArea-150"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"remark\")"
        },
        "rows": {
          "type": "code",
          "value": "4"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"60%\" }"
        },
        "value": {
          "type": "code",
          "value": "props.remark"
        }
      },
      "seq": 150,
      "void": true
    },
    {
      "from": null,
      "id": 151,
      "name": "div",
      "parent": 148,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardSurgicalSafetyChecklistSignoutUX-div-151"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\", margin: \"0px 10px\" }"
        }
      },
      "seq": 151,
      "void": false
    },
    {
      "from": null,
      "id": 152,
      "name": "label",
      "parent": 151,
      "props": {
        "children": {
          "type": "value",
          "value": "Closure"
        },
        "id": {
          "type": "value",
          "value": "CardSurgicalSafetyChecklistSignoutUX-label-152"
        },
        "style": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 152,
      "void": false
    },
    {
      "from": null,
      "id": 153,
      "name": "label",
      "parent": 151,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "id": {
          "type": "value",
          "value": "CardSurgicalSafetyChecklistSignoutUX-label-153"
        },
        "style": {
          "type": "code",
          "value": "{ color: \"red\" }"
        }
      },
      "seq": 153,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 154,
      "name": "DateTextBox",
      "parent": 148,
      "props": {
        "id": {
          "type": "value",
          "value": "CardSurgicalSafetyChecklistSignoutUX-DateTextBox-154"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeDate(\"closureDate\")"
        },
        "value": {
          "type": "code",
          "value": "props.closureDate"
        }
      },
      "seq": 154,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 156,
      "name": "Input",
      "parent": 90,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.surgicalSafety?.data?.is_blood_administered ? false : true"
        },
        "id": {
          "type": "value",
          "value": "CardSurgicalSafetyChecklistSignoutUX-Input-156"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"blood_administered\")"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"80%\" }"
        },
        "value": {
          "type": "code",
          "value": "props.surgicalSafety?.data?.blood_administered"
        }
      },
      "seq": 156,
      "void": false
    },
    {
      "from": null,
      "id": 157,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardSurgicalSafetyChecklistSignoutUX-div-157"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", justifyContent: \"space-between\"}"
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": null,
      "id": 159,
      "name": "label",
      "parent": 157,
      "props": {
        "children": {
          "type": "value",
          "value": "Surgical Safety Checklist - Sign out"
        },
        "id": {
          "type": "value",
          "value": "CardSurgicalSafetyChecklistSignoutUX-label-159"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\", fontSize: \"18px\" }"
        }
      },
      "seq": 159,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 160,
      "name": "Label",
      "parent": 157,
      "props": {
        "children": {
          "type": "code",
          "value": "props.status"
        },
        "id": {
          "type": "value",
          "value": "CardSurgicalSafetyChecklistSignoutUX-Label-160"
        },
        "style": {
          "type": "code",
          "value": "{display: props.status? \"\":\"none\"}"
        }
      },
      "seq": 160,
      "void": false
    },
    {
      "from": null,
      "id": 161,
      "name": "div",
      "parent": 60,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonSave"
        },
        "id": {
          "type": "value",
          "value": "CardSurgicalSafetyChecklistSignoutUX-div-161"
        }
      },
      "seq": 161,
      "void": false
    },
    {
      "from": null,
      "id": 163,
      "name": "div",
      "parent": 60,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonConfirm"
        },
        "id": {
          "type": "value",
          "value": "CardSurgicalSafetyChecklistSignoutUX-div-163"
        },
        "style": {
          "type": "code",
          "value": "{ margin: \"0px 10px\" }"
        }
      },
      "seq": 163,
      "void": false
    },
    {
      "from": null,
      "id": 164,
      "name": "div",
      "parent": 60,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonUnconfirm"
        },
        "id": {
          "type": "value",
          "value": "CardSurgicalSafetyChecklistSignoutUX-div-164"
        }
      },
      "seq": 164,
      "void": false
    },
    {
      "from": null,
      "id": 165,
      "name": "div",
      "parent": 148,
      "props": {
        "children": {
          "type": "code",
          "value": "props.timeTextBox"
        },
        "id": {
          "type": "value",
          "value": "CardSurgicalSafetyChecklistSignoutUX-div-165"
        }
      },
      "seq": 165,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": false,
  "memo": false,
  "name": "CardSurgicalSafetyChecklistSignoutUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */

import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  FormGroup,
  FormField,
  Dropdown,
  Input,
  Checkbox
} from 'semantic-ui-react'

const ModSelectOperatingDatetTimeUX = (props: any) => {
    return(
      <div>
        <div
          id="ModSelectOperatingDatetTimeUX-div-1"
          style={{height:"100%", width: "100%", position: "relative"}}>

          <div
            id="ModSelectOperatingDatetTimeUX-div-2"
            style={{padding: "10px", fontSize: "18px", fontWeight: "bold", backgroundColor: "#066cbb", color: "white"}}>
            จองวันและเวลาผ่าตัด
          </div>
          <div
            id="ModSelectOperatingDatetTimeUX-div-128"
            style={{position: "absolute", right: 0}}>

            <div
              id="ModSelectOperatingDatetTimeUX-div-130">
              {props.statusName}
            </div>
          </div>
          <div
            className={`ui form ${props.disabledOn ? "--override-disabled" : ""}`}
            id="ModSelectOperatingDatetTimeUX-div-3"
            style={{padding: props.isAdmit ? "15px 0px 5px 0px" : "15px 0px", marginLeft: "-1.5rem"}}>

            <FormGroup
              id="ModSelectOperatingDatetTimeUX-FormGroup-70"
              inline={true}>
              <FormField
                id="ModSelectOperatingDatetTimeUX-FormField-71"
                inline={true}
                width={16}>
                <div
                  id="ModSelectOperatingDatetTimeUX-div-72"
                  style={{width: "100%", textAlign: "center"}}>
                  {props.timeSpent}
                </div>
              </FormField>
            </FormGroup>
            <FormGroup
              id="ModSelectOperatingDatetTimeUX-FormGroup-4"
              inline={true}>
              <FormField
                id="ModSelectOperatingDatetTimeUX-FormField-11"
                inline={true}
                width="1">
              </FormField>
              <FormField
                id="ModSelectOperatingDatetTimeUX-FormField-12"
                inline={true}
                width={2}>
                <label
                  id="ModSelectOperatingDatetTimeUX-label-32">
                  HN:
                </label>
              </FormField>
              <FormField
                id="ModSelectOperatingDatetTimeUX-FormField-13"
                inline={true}
                width={2}>
                <label
                  id="ModSelectOperatingDatetTimeUX-label-43">
                  {props.patientHN}
                </label>
              </FormField>
              <FormField
                id="ModSelectOperatingDatetTimeUX-FormField-14"
                inline={true}
                width={1}>
                <label
                  id="ModSelectOperatingDatetTimeUX-label-33"
                  style={{minWidth: "max-content"}}>
                  ชื่อ-สกุล
                </label>
              </FormField>
              <FormField
                id="ModSelectOperatingDatetTimeUX-FormField-15"
                inline={true}
                width={8}>
                <label
                  id="ModSelectOperatingDatetTimeUX-label-44">
                  {props.patientName}
                </label>
              </FormField>
            </FormGroup>
            <FormGroup
              id="ModSelectOperatingDatetTimeUX-FormGroup-5"
              inline={true}>
              <FormField
                id="ModSelectOperatingDatetTimeUX-FormField-16"
                inline={true}
                width={1}>
              </FormField>
              <FormField
                id="ModSelectOperatingDatetTimeUX-FormField-17"
                inline={true}
                width={2}>
                <label
                  id="ModSelectOperatingDatetTimeUX-label-36">
                  วันนัดหมาย:
                </label>
              </FormField>
              <FormField
                id="ModSelectOperatingDatetTimeUX-FormField-21"
                inline={true}
                width={3}>
                <label
                  id="ModSelectOperatingDatetTimeUX-label-37">
                  {props.selectedDate}
                </label>
              </FormField>
              <FormField
                id="ModSelectOperatingDatetTimeUX-FormField-28"
                inline={true}
                width={1}>
                <label
                  id="ModSelectOperatingDatetTimeUX-label-38">
                  จาก
                </label>
              </FormField>
              <FormField
                id="ModSelectOperatingDatetTimeUX-FormField-29"
                inline={true}
                width={3}>
                <div
                  id="ModSelectOperatingDatetTimeUX-div-41"
                  style={{width: "100%"}}>
                  {props.startTime}
                </div>
              </FormField>
              <FormField
                id="ModSelectOperatingDatetTimeUX-FormField-30"
                inline={true}
                width={1}>
                <label
                  id="ModSelectOperatingDatetTimeUX-label-39"
                  style={{textAlign: "center", width: "100%"}}>
                  ถึง
                </label>
              </FormField>
              <FormField
                id="ModSelectOperatingDatetTimeUX-FormField-31"
                inline={true}
                width={3}>
                <div
                  id="ModSelectOperatingDatetTimeUX-div-42"
                  style={{width: "100%"}}>
                  {props.endTime}
                </div>
              </FormField>
            </FormGroup>
            <FormGroup
              id="ModSelectOperatingDatetTimeUX-FormGroup-6"
              inline={true}>
              <FormField
                id="ModSelectOperatingDatetTimeUX-FormField-18"
                inline={true}
                width={1}>
              </FormField>
              <FormField
                id="ModSelectOperatingDatetTimeUX-FormField-19"
                inline={true}
                width={2}>
                <label
                  className="required"
                  id="ModSelectOperatingDatetTimeUX-label-45">
                  แพทย์ผ่าตัด
                </label>
              </FormField>
              <FormField
                id="ModSelectOperatingDatetTimeUX-FormField-20"
                inline={true}
                width={7}>
                <Dropdown
                  disabled={props.disabledDoctor || true}
                  fluid={true}
                  id="ModSelectOperatingDatetTimeUX-Dropdown-46"
                  onChange={props.onChangeDoctor}
                  options={props.doctorOptions}
                  search={true}
                  selection={true}
                  style={{width: "100%"}}
                  value={props.doctor}>
                </Dropdown>
              </FormField>
            </FormGroup>
            <FormGroup
              id="ModSelectOperatingDatetTimeUX-FormGroup-47"
              inline={true}>
              <FormField
                id="ModSelectOperatingDatetTimeUX-FormField-48"
                inline={true}
                width={1}>
              </FormField>
              <FormField
                id="ModSelectOperatingDatetTimeUX-FormField-49"
                inline={true}
                width={2}>
                <label
                  className="required"
                  id="ModSelectOperatingDatetTimeUX-label-50">
                  วิสัญญีแพทย์
                </label>
              </FormField>
              <FormField
                id="ModSelectOperatingDatetTimeUX-FormField-51"
                inline={true}
                width={7}>
                <Dropdown
                  disabled={props.disabledAnesthesia}
                  fluid={true}
                  id="ModSelectOperatingDatetTimeUX-Dropdown-52"
                  onChange={props.onChangeAnesthesia}
                  options={props.anesthesiaOptions}
                  search={true}
                  selection={true}
                  style={{width: "100%"}}
                  value={props.anesthesia}>
                </Dropdown>
              </FormField>
              <FormField
                id="ModSelectOperatingDatetTimeUX-FormField-118"
                inline={true}>
              </FormField>
              <FormField
                id="ModSelectOperatingDatetTimeUX-FormField-119"
                inline={true}
                width={2}>
                <label
                  id="ModSelectOperatingDatetTimeUX-label-121"
                  style={{minWidth: "max-content"}}>
                  วิธีระงับความรู้สึก
                </label>
              </FormField>
              <FormField
                id="ModSelectOperatingDatetTimeUX-FormField-120"
                inline={true}>
                <div
                  id="ModSelectOperatingDatetTimeUX-div-122">
                  {props.anesthesiaMethod || "-"}
                </div>
              </FormField>
            </FormGroup>
            <FormGroup
              id="ModSelectOperatingDatetTimeUX-FormGroup-53"
              inline={true}>
              <FormField
                id="ModSelectOperatingDatetTimeUX-FormField-54"
                inline={true}
                width={1}>
              </FormField>
              <FormField
                id="ModSelectOperatingDatetTimeUX-FormField-55"
                inline={true}
                width={2}>
                <label
                  className="required"
                  id="ModSelectOperatingDatetTimeUX-label-56">
                  ห้องผ่าตัด
                </label>
              </FormField>
              <FormField
                id="ModSelectOperatingDatetTimeUX-FormField-57"
                inline={true}
                width={7}>
                <Dropdown
                  disabled={props.disabledOperatingRoom}
                  fluid={true}
                  id="ModSelectOperatingDatetTimeUX-Dropdown-58"
                  loading={props.loadingRoomOption || false}
                  onChange={props.onChangeOperatingRoom}
                  options={props.operatingRoomOptions}
                  search={true}
                  selection={true}
                  style={{width: "100%"}}
                  value={props.operatingRoom}>
                </Dropdown>
              </FormField>
              <FormField
                id="ModSelectOperatingDatetTimeUX-FormField-123"
                inline={true}>
              </FormField>
              <FormField
                id="ModSelectOperatingDatetTimeUX-FormField-124"
                inline={true}
                width={2}>
                <label
                  id="ModSelectOperatingDatetTimeUX-label-126">
                  Procedure Type
                </label>
              </FormField>
              <FormField
                id="ModSelectOperatingDatetTimeUX-FormField-125"
                inline={true}>
                <div
                  id="ModSelectOperatingDatetTimeUX-div-127">
                  {props.procedureType || "-"}
                </div>
              </FormField>
            </FormGroup>
            <FormGroup
              id="ModSelectOperatingDatetTimeUX-FormGroup-9"
              inline={true}>
              <FormField
                id="ModSelectOperatingDatetTimeUX-FormField-62"
                inline={true}
                width={1}>
              </FormField>
              <FormField
                id="ModSelectOperatingDatetTimeUX-FormField-59"
                inline={true}
                width={15}>
                <label
                  id="ModSelectOperatingDatetTimeUX-label-67">
                  รายการผ่าตัด
                </label>
              </FormField>
            </FormGroup>
            <FormGroup
              id="ModSelectOperatingDatetTimeUX-FormGroup-10"
              inline={true}>
              <FormField
                id="ModSelectOperatingDatetTimeUX-FormField-61"
                inline={true}
                width={1}>
              </FormField>
              <FormField
                id="ModSelectOperatingDatetTimeUX-FormField-63"
                inline={true}
                width={14}>
                <div
                  id="ModSelectOperatingDatetTimeUX-div-68"
                  style={{width: "100%"}}>
                  {props.treatmentList}
                </div>
              </FormField>
            </FormGroup>
          </div>
          <div
            className={`ui form ${props.disabledOn ? "--override-disabled" : ""}`}
            id="ModSelectOperatingDatetTimeUX-div-73"
            style={{padding: "0px 0px 15px 0px", display: "none"}}>

            <FormGroup
              id="ModSelectOperatingDatetTimeUX-FormGroup-79"
              inline={true}>
              <FormField
                id="ModSelectOperatingDatetTimeUX-FormField-80"
                inline={true}
                width={1}>
              </FormField>
              <FormField
                id="ModSelectOperatingDatetTimeUX-FormField-83"
                inline={true}
                width={6}>
                <label
                  id="ModSelectOperatingDatetTimeUX-label-131"
                  style={{width: "92px"}}>
                  นัดหมาย Admit
                </label>
                <label
                  id="ModSelectOperatingDatetTimeUX-label-84">
                  {props.admitCase}
                </label>
              </FormField>
              <FormField
                id="ModSelectOperatingDatetTimeUX-FormField-85"
                inline={true}>
                <label
                  id="ModSelectOperatingDatetTimeUX-label-86"
                  style={{width: "96px"}}>
                  นอน รพจำนวน
                </label>
              </FormField>
              <FormField
                id="ModSelectOperatingDatetTimeUX-FormField-87"
                inline={true}
                style={{paddingRight: 0}}
                width={2}>
                <Input
                  disabled={props.disabledOn}
                  id="ModSelectOperatingDatetTimeUX-Input-93"
                  onChange={props.onChangeStayDays}
                  style={{width: "100%"}}
                  value={props.stayDays}>
                </Input>
              </FormField>
              <FormField
                id="ModSelectOperatingDatetTimeUX-FormField-89"
                inline={true}
                width={1}>
                <label
                  id="ModSelectOperatingDatetTimeUX-label-90"
                  style={{textAlign: "center", width: "100%"}}>
                  วัน
                </label>
              </FormField>
            </FormGroup>
            <FormGroup
              id="ModSelectOperatingDatetTimeUX-FormGroup-94"
              inline={true}>
              <FormField
                id="ModSelectOperatingDatetTimeUX-FormField-95"
                inline={true}
                width={1}>
              </FormField>
              <FormField
                id="ModSelectOperatingDatetTimeUX-FormField-98"
                inline={true}
                width={6}>
                <label
                  id="ModSelectOperatingDatetTimeUX-label-133"
                  style={{width: "92px"}}>
                  วันที่ Admit
                </label>
                <div
                  id="ModSelectOperatingDatetTimeUX-div-111">
                  {props.admitDate}
                </div>
              </FormField>
              <FormField
                id="ModSelectOperatingDatetTimeUX-FormField-100"
                inline={true}>
                <label
                  id="ModSelectOperatingDatetTimeUX-label-101"
                  style={{width: "96px"}}>
                  เวลา
                </label>
              </FormField>
              <FormField
                id="ModSelectOperatingDatetTimeUX-FormField-102"
                inline={true}
                style={{paddingRight: 0}}
                width={2}>
                <div
                  id="ModSelectOperatingDatetTimeUX-div-106"
                  style={{width: "100%"}}>
                  {props.admitTime}
                </div>
              </FormField>
            </FormGroup>
            <FormGroup
              id="ModSelectOperatingDatetTimeUX-FormGroup-107"
              inline={true}>
              <FormField
                id="ModSelectOperatingDatetTimeUX-FormField-108"
                inline={true}
                width={1}>
              </FormField>
              <FormField
                id="ModSelectOperatingDatetTimeUX-FormField-109"
                inline={true}
                width={6}>
                <Checkbox
                  checked={props.checkedRequestICU}
                  disabled={props.disabledOn}
                  id="ModSelectOperatingDatetTimeUX-Checkbox-110"
                  label="Request ICU"
                  onChange={props.onChangeCheckedRequestICU}
                  style={{fontWeight: "bold", padding: "5px 0px"}}>
                </Checkbox>
              </FormField>
              <FormField
                id="ModSelectOperatingDatetTimeUX-FormField-112"
                inline={true}>
                <label
                  id="ModSelectOperatingDatetTimeUX-label-113"
                  style={{width: "96px"}}>
                  นอน ICU จำนวน
                </label>
              </FormField>
              <FormField
                id="ModSelectOperatingDatetTimeUX-FormField-114"
                inline={true}
                style={{paddingRight: 0}}
                width={2}>
                <Input
                  disabled={props.disabledOn}
                  id="ModSelectOperatingDatetTimeUX-Input-115"
                  onChange={props.onChangeStayDaysICU}
                  style={{width: "100%"}}
                  value={props.stayDaysICU}>
                </Input>
              </FormField>
              <FormField
                id="ModSelectOperatingDatetTimeUX-FormField-116"
                inline={true}
                width={1}>
                <label
                  id="ModSelectOperatingDatetTimeUX-label-117"
                  style={{textAlign: "center", width: "100%"}}>
                  วัน
                </label>
              </FormField>
            </FormGroup>
          </div>
          <div
            className={`ui form ${props.disabledOn ? "--override-disabled" : ""}`}
            id="ModSelectOperatingDatetTimeUX-div-134"
            style={{paddingLeft: "30px"}}>

            <FormGroup
              id="ModSelectOperatingDatetTimeUX-FormGroup-135"
              inline={true}>
              <FormField
                id="ModSelectOperatingDatetTimeUX-FormField-138"
                inline={true}
                width={2}>
                <label
                  id="ModSelectOperatingDatetTimeUX-label-143">
                  Case :
                </label>
                <label
                  id="ModSelectOperatingDatetTimeUX-label-144"
                  style={{ display: "flex", justifyContent: "center" }}>
                  {props.case || ""}
                </label>
              </FormField>
              <FormField
                id="ModSelectOperatingDatetTimeUX-FormField-139"
                inline={true}
                style={{ display: props.isAdmit? "": "none" }}
                width={5}>
                <label
                  id="ModSelectOperatingDatetTimeUX-label-146"
                  style={{width: "92px"}}>
                  วันที่ นัด Admit
                </label>
                <div
                  id="ModSelectOperatingDatetTimeUX-div-145">
                  {props.admitDate}
                </div>
              </FormField>
              <FormField
                id="ModSelectOperatingDatetTimeUX-FormField-140"
                inline={true}
                style={{ display: props.isAdmit? "": "none" }}
                width={6}>
                <label
                  id="ModSelectOperatingDatetTimeUX-label-147"
                  style={{width: "92px"}}>
                  เวลา Admit
                </label>
                <div
                  id="ModSelectOperatingDatetTimeUX-div-164"
                  style={{ width: "100%" }}>
                  {props.admitTime}
                </div>
              </FormField>
            </FormGroup>
            <FormGroup
              id="ModSelectOperatingDatetTimeUX-FormGroup-136"
              inline={true}
              style={{ display: props.isAdmit? "": "none" }}>
              <FormField
                id="ModSelectOperatingDatetTimeUX-FormField-159"
                inline={true}
                width={2}>
              </FormField>
              <FormField
                id="ModSelectOperatingDatetTimeUX-FormField-149"
                inline={true}
                width={2}>
                <label
                  id="ModSelectOperatingDatetTimeUX-label-155">
                  นอน รพ. จำนวน
                </label>
              </FormField>
              <FormField
                id="ModSelectOperatingDatetTimeUX-FormField-150"
                inline={true}
                style={{paddingRight: 0}}
                width={2}>
                <Input
                  disabled={props.disabledOn}
                  id="ModSelectOperatingDatetTimeUX-Input-156"
                  name="admitStayDate"
                  onChange={props.onChangeStayDays}
                  style={{width: "100%"}}
                  value={props.stayDays}>
                </Input>
              </FormField>
              <FormField
                id="ModSelectOperatingDatetTimeUX-FormField-157"
                inline={true}
                width={1}>
                <label
                  id="ModSelectOperatingDatetTimeUX-label-158"
                  style={{textAlign: "center", width: "100%"}}>
                  วัน
                </label>
              </FormField>
              <FormField
                id="ModSelectOperatingDatetTimeUX-FormField-151"
                inline={true}
                width={7}>
                <Checkbox
                  checked={props.checkedRequestICU}
                  id="ModSelectOperatingDatetTimeUX-Checkbox-152"
                  label="Request ICU"
                  name="admitRequestICU"
                  onChange={props.onChangeValue}
                  style={{minWidth: "max-content", fontWeight: "bold" }}>
                </Checkbox>
                <Input
                  fluid={true}
                  id="ModSelectOperatingDatetTimeUX-Input-153"
                  name="admitICUStay"
                  onChange={props.onChangeValue}
                  style={{width: "40%"}}
                  type="number"
                  value={props.stayDaysICU || ""}>
                </Input>
                <label
                  id="ModSelectOperatingDatetTimeUX-label-154"
                  style={{fontWeight: "bold", marginLeft: "0.5rem"}}>
                  วัน
                </label>
              </FormField>
            </FormGroup>
            <FormGroup
              id="ModSelectOperatingDatetTimeUX-FormGroup-137"
              inline={true}>
              <FormField
                id="ModSelectOperatingDatetTimeUX-FormField-160"
                inline={true}
                width={2}>
                <label
                  id="ModSelectOperatingDatetTimeUX-label-162">
                  แผนกที่ติดต่อ
                </label>
              </FormField>
              <FormField
                id="ModSelectOperatingDatetTimeUX-FormField-161"
                inline={true}
                width={10}>
                <Dropdown
                  disabled={props.disabledDivision}
                  fluid={true}
                  id="ModSelectOperatingDatetTimeUX-Dropdown-163"
                  name="division"
                  onChange={props.onChangeValue}
                  options={props.divisionOptions}
                  selection={true}
                  style={{ width: "100%" }}
                  value={props.division}>
                </Dropdown>
              </FormField>
            </FormGroup>
          </div>
          <div
            className="ui form"
            id="ModSelectOperatingDatetTimeUX-div-74"
            style={{padding: "0 15px 15px"}}>

            <FormGroup
              id="ModSelectOperatingDatetTimeUX-FormGroup-75"
              inline={true}>
              <FormField
                id="ModSelectOperatingDatetTimeUX-FormField-76"
                inline={true}
                width={8}>
              </FormField>
              <FormField
                id="ModSelectOperatingDatetTimeUX-FormField-77"
                inline={true}
                width={8}>
                <div
                  id="ModSelectOperatingDatetTimeUX-div-78"
                  style={{width: "100%", display: "flex", justifyContent: props.centeredButton ? "":"flex-end", marginLeft: props.centeredButton? "-37.5px":""}}>
                  {props.buttonList}
                </div>
              </FormField>
            </FormGroup>
          </div>
        </div>
      </div>
    )
}


export default ModSelectOperatingDatetTimeUX

export const screenPropsDefault = {}

/* Date Time : Thu Feb 27 2025 12:57:15 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "ModSelectOperatingDatetTimeUX-div-1"
        },
        "style": {
          "type": "code",
          "value": "{height:\"100%\", width: \"100%\", position: \"relative\"}"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 2,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "จองวันและเวลาผ่าตัด"
        },
        "id": {
          "type": "value",
          "value": "ModSelectOperatingDatetTimeUX-div-2"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"10px\", fontSize: \"18px\", fontWeight: \"bold\", backgroundColor: \"#066cbb\", color: \"white\"}"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": null,
      "id": 3,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "code",
          "value": "`ui form ${props.disabledOn ? \"--override-disabled\" : \"\"}`"
        },
        "id": {
          "type": "value",
          "value": "ModSelectOperatingDatetTimeUX-div-3"
        },
        "style": {
          "type": "code",
          "value": "{padding: props.isAdmit ? \"15px 0px 5px 0px\" : \"15px 0px\", marginLeft: \"-1.5rem\"}"
        }
      },
      "seq": 73,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 4,
      "name": "FormGroup",
      "parent": 3,
      "props": {
        "id": {
          "type": "value",
          "value": "ModSelectOperatingDatetTimeUX-FormGroup-4"
        },
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 5,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 5,
      "name": "FormGroup",
      "parent": 3,
      "props": {
        "id": {
          "type": "value",
          "value": "ModSelectOperatingDatetTimeUX-FormGroup-5"
        },
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 6,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 6,
      "name": "FormGroup",
      "parent": 3,
      "props": {
        "id": {
          "type": "value",
          "value": "ModSelectOperatingDatetTimeUX-FormGroup-6"
        },
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 7,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 9,
      "name": "FormGroup",
      "parent": 3,
      "props": {
        "id": {
          "type": "value",
          "value": "ModSelectOperatingDatetTimeUX-FormGroup-9"
        },
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 53,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 10,
      "name": "FormGroup",
      "parent": 3,
      "props": {
        "id": {
          "type": "value",
          "value": "ModSelectOperatingDatetTimeUX-FormGroup-10"
        },
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 64,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 11,
      "name": "FormField",
      "parent": 4,
      "props": {
        "id": {
          "type": "value",
          "value": "ModSelectOperatingDatetTimeUX-FormField-11"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "value",
          "value": "1"
        }
      },
      "seq": 11,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 12,
      "name": "FormField",
      "parent": 4,
      "props": {
        "id": {
          "type": "value",
          "value": "ModSelectOperatingDatetTimeUX-FormField-12"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 12,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 13,
      "name": "FormField",
      "parent": 4,
      "props": {
        "id": {
          "type": "value",
          "value": "ModSelectOperatingDatetTimeUX-FormField-13"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 13,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 14,
      "name": "FormField",
      "parent": 4,
      "props": {
        "id": {
          "type": "value",
          "value": "ModSelectOperatingDatetTimeUX-FormField-14"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "1"
        }
      },
      "seq": 14,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 15,
      "name": "FormField",
      "parent": 4,
      "props": {
        "id": {
          "type": "value",
          "value": "ModSelectOperatingDatetTimeUX-FormField-15"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "8"
        }
      },
      "seq": 15,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 16,
      "name": "FormField",
      "parent": 5,
      "props": {
        "id": {
          "type": "value",
          "value": "ModSelectOperatingDatetTimeUX-FormField-16"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "1"
        }
      },
      "seq": 16,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 17,
      "name": "FormField",
      "parent": 5,
      "props": {
        "id": {
          "type": "value",
          "value": "ModSelectOperatingDatetTimeUX-FormField-17"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 17,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 18,
      "name": "FormField",
      "parent": 6,
      "props": {
        "className": {
          "type": "code",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "ModSelectOperatingDatetTimeUX-FormField-18"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "1"
        }
      },
      "seq": 18,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 19,
      "name": "FormField",
      "parent": 6,
      "props": {
        "id": {
          "type": "value",
          "value": "ModSelectOperatingDatetTimeUX-FormField-19"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 19,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 20,
      "name": "FormField",
      "parent": 6,
      "props": {
        "id": {
          "type": "value",
          "value": "ModSelectOperatingDatetTimeUX-FormField-20"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "7"
        }
      },
      "seq": 20,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 21,
      "name": "FormField",
      "parent": 5,
      "props": {
        "id": {
          "type": "value",
          "value": "ModSelectOperatingDatetTimeUX-FormField-21"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 21,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 28,
      "name": "FormField",
      "parent": 5,
      "props": {
        "id": {
          "type": "value",
          "value": "ModSelectOperatingDatetTimeUX-FormField-28"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "1"
        }
      },
      "seq": 28,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 29,
      "name": "FormField",
      "parent": 5,
      "props": {
        "id": {
          "type": "value",
          "value": "ModSelectOperatingDatetTimeUX-FormField-29"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 29,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 30,
      "name": "FormField",
      "parent": 5,
      "props": {
        "id": {
          "type": "value",
          "value": "ModSelectOperatingDatetTimeUX-FormField-30"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "1"
        }
      },
      "seq": 30,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 31,
      "name": "FormField",
      "parent": 5,
      "props": {
        "id": {
          "type": "value",
          "value": "ModSelectOperatingDatetTimeUX-FormField-31"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 31,
      "void": false
    },
    {
      "from": null,
      "id": 32,
      "name": "label",
      "parent": 12,
      "props": {
        "children": {
          "type": "value",
          "value": "HN:"
        },
        "id": {
          "type": "value",
          "value": "ModSelectOperatingDatetTimeUX-label-32"
        }
      },
      "seq": 32,
      "void": false
    },
    {
      "from": null,
      "id": 33,
      "name": "label",
      "parent": 14,
      "props": {
        "children": {
          "type": "value",
          "value": "ชื่อ-สกุล"
        },
        "id": {
          "type": "value",
          "value": "ModSelectOperatingDatetTimeUX-label-33"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        }
      },
      "seq": 33,
      "void": false
    },
    {
      "from": null,
      "id": 36,
      "name": "label",
      "parent": 17,
      "props": {
        "children": {
          "type": "value",
          "value": "วันนัดหมาย:"
        },
        "id": {
          "type": "value",
          "value": "ModSelectOperatingDatetTimeUX-label-36"
        }
      },
      "seq": 36,
      "void": false
    },
    {
      "from": null,
      "id": 37,
      "name": "label",
      "parent": 21,
      "props": {
        "children": {
          "type": "code",
          "value": "props.selectedDate"
        },
        "id": {
          "type": "value",
          "value": "ModSelectOperatingDatetTimeUX-label-37"
        }
      },
      "seq": 37,
      "void": false
    },
    {
      "from": null,
      "id": 38,
      "name": "label",
      "parent": 28,
      "props": {
        "children": {
          "type": "value",
          "value": "จาก"
        },
        "id": {
          "type": "value",
          "value": "ModSelectOperatingDatetTimeUX-label-38"
        }
      },
      "seq": 38,
      "void": false
    },
    {
      "from": null,
      "id": 39,
      "name": "label",
      "parent": 30,
      "props": {
        "children": {
          "type": "value",
          "value": "ถึง"
        },
        "id": {
          "type": "value",
          "value": "ModSelectOperatingDatetTimeUX-label-39"
        },
        "style": {
          "type": "code",
          "value": "{textAlign: \"center\", width: \"100%\"}"
        }
      },
      "seq": 39,
      "void": false
    },
    {
      "from": null,
      "id": 41,
      "name": "div",
      "parent": 29,
      "props": {
        "children": {
          "type": "code",
          "value": "props.startTime"
        },
        "id": {
          "type": "value",
          "value": "ModSelectOperatingDatetTimeUX-div-41"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 41,
      "void": false
    },
    {
      "from": null,
      "id": 42,
      "name": "div",
      "parent": 31,
      "props": {
        "children": {
          "type": "code",
          "value": "props.endTime"
        },
        "id": {
          "type": "value",
          "value": "ModSelectOperatingDatetTimeUX-div-42"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 42,
      "void": false
    },
    {
      "from": null,
      "id": 43,
      "name": "label",
      "parent": 13,
      "props": {
        "children": {
          "type": "code",
          "value": "props.patientHN"
        },
        "id": {
          "type": "value",
          "value": "ModSelectOperatingDatetTimeUX-label-43"
        }
      },
      "seq": 43,
      "void": false
    },
    {
      "from": null,
      "id": 44,
      "name": "label",
      "parent": 15,
      "props": {
        "children": {
          "type": "code",
          "value": "props.patientName"
        },
        "id": {
          "type": "value",
          "value": "ModSelectOperatingDatetTimeUX-label-44"
        }
      },
      "seq": 44,
      "void": false
    },
    {
      "from": null,
      "id": 45,
      "name": "label",
      "parent": 19,
      "props": {
        "children": {
          "type": "value",
          "value": "แพทย์ผ่าตัด"
        },
        "className": {
          "type": "value",
          "value": "required"
        },
        "id": {
          "type": "value",
          "value": "ModSelectOperatingDatetTimeUX-label-45"
        }
      },
      "seq": 45,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 46,
      "name": "Dropdown",
      "parent": 20,
      "props": {
        "clearable": {
          "type": "code",
          "value": ""
        },
        "compact": {
          "type": "code",
          "value": ""
        },
        "disabled": {
          "type": "code",
          "value": "props.disabledDoctor || true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "id": {
          "type": "value",
          "value": "ModSelectOperatingDatetTimeUX-Dropdown-46"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeDoctor"
        },
        "options": {
          "type": "code",
          "value": "props.doctorOptions"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.doctor"
        }
      },
      "seq": 46,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 47,
      "name": "FormGroup",
      "parent": 3,
      "props": {
        "id": {
          "type": "value",
          "value": "ModSelectOperatingDatetTimeUX-FormGroup-47"
        },
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 8,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 48,
      "name": "FormField",
      "parent": 47,
      "props": {
        "className": {
          "type": "code",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "ModSelectOperatingDatetTimeUX-FormField-48"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "1"
        }
      },
      "seq": 48,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 49,
      "name": "FormField",
      "parent": 47,
      "props": {
        "id": {
          "type": "value",
          "value": "ModSelectOperatingDatetTimeUX-FormField-49"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 49,
      "void": false
    },
    {
      "from": null,
      "id": 50,
      "name": "label",
      "parent": 49,
      "props": {
        "children": {
          "type": "value",
          "value": "วิสัญญีแพทย์"
        },
        "className": {
          "type": "value",
          "value": "required"
        },
        "id": {
          "type": "value",
          "value": "ModSelectOperatingDatetTimeUX-label-50"
        }
      },
      "seq": 50,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 51,
      "name": "FormField",
      "parent": 47,
      "props": {
        "id": {
          "type": "value",
          "value": "ModSelectOperatingDatetTimeUX-FormField-51"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "7"
        }
      },
      "seq": 51,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 52,
      "name": "Dropdown",
      "parent": 51,
      "props": {
        "clearable": {
          "type": "code",
          "value": ""
        },
        "disabled": {
          "type": "code",
          "value": "props.disabledAnesthesia"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "id": {
          "type": "value",
          "value": "ModSelectOperatingDatetTimeUX-Dropdown-52"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeAnesthesia"
        },
        "options": {
          "type": "code",
          "value": "props.anesthesiaOptions"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.anesthesia"
        }
      },
      "seq": 52,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 53,
      "name": "FormGroup",
      "parent": 3,
      "props": {
        "id": {
          "type": "value",
          "value": "ModSelectOperatingDatetTimeUX-FormGroup-53"
        },
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 47,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 54,
      "name": "FormField",
      "parent": 53,
      "props": {
        "className": {
          "type": "code",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "ModSelectOperatingDatetTimeUX-FormField-54"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "1"
        }
      },
      "seq": 54,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 55,
      "name": "FormField",
      "parent": 53,
      "props": {
        "id": {
          "type": "value",
          "value": "ModSelectOperatingDatetTimeUX-FormField-55"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 55,
      "void": false
    },
    {
      "from": null,
      "id": 56,
      "name": "label",
      "parent": 55,
      "props": {
        "children": {
          "type": "value",
          "value": "ห้องผ่าตัด"
        },
        "className": {
          "type": "value",
          "value": "required"
        },
        "id": {
          "type": "value",
          "value": "ModSelectOperatingDatetTimeUX-label-56"
        }
      },
      "seq": 56,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 57,
      "name": "FormField",
      "parent": 53,
      "props": {
        "id": {
          "type": "value",
          "value": "ModSelectOperatingDatetTimeUX-FormField-57"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "7"
        }
      },
      "seq": 57,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 58,
      "name": "Dropdown",
      "parent": 57,
      "props": {
        "clearable": {
          "type": "code",
          "value": ""
        },
        "disabled": {
          "type": "code",
          "value": "props.disabledOperatingRoom"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "id": {
          "type": "value",
          "value": "ModSelectOperatingDatetTimeUX-Dropdown-58"
        },
        "loading": {
          "type": "code",
          "value": "props.loadingRoomOption || false"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeOperatingRoom"
        },
        "options": {
          "type": "code",
          "value": "props.operatingRoomOptions"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.operatingRoom"
        }
      },
      "seq": 58,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 59,
      "name": "FormField",
      "parent": 9,
      "props": {
        "id": {
          "type": "value",
          "value": "ModSelectOperatingDatetTimeUX-FormField-59"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "15"
        }
      },
      "seq": 62,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 61,
      "name": "FormField",
      "parent": 10,
      "props": {
        "id": {
          "type": "value",
          "value": "ModSelectOperatingDatetTimeUX-FormField-61"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "1"
        }
      },
      "seq": 61,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 62,
      "name": "FormField",
      "parent": 9,
      "props": {
        "id": {
          "type": "value",
          "value": "ModSelectOperatingDatetTimeUX-FormField-62"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "1"
        }
      },
      "seq": 59,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 63,
      "name": "FormField",
      "parent": 10,
      "props": {
        "id": {
          "type": "value",
          "value": "ModSelectOperatingDatetTimeUX-FormField-63"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "14"
        }
      },
      "seq": 63,
      "void": false
    },
    {
      "from": null,
      "id": 67,
      "name": "label",
      "parent": 59,
      "props": {
        "children": {
          "type": "value",
          "value": "รายการผ่าตัด"
        },
        "id": {
          "type": "value",
          "value": "ModSelectOperatingDatetTimeUX-label-67"
        }
      },
      "seq": 67,
      "void": false
    },
    {
      "from": null,
      "id": 68,
      "name": "div",
      "parent": 63,
      "props": {
        "children": {
          "type": "code",
          "value": "props.treatmentList"
        },
        "id": {
          "type": "value",
          "value": "ModSelectOperatingDatetTimeUX-div-68"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 68,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 70,
      "name": "FormGroup",
      "parent": 3,
      "props": {
        "id": {
          "type": "value",
          "value": "ModSelectOperatingDatetTimeUX-FormGroup-70"
        },
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 71,
      "name": "FormField",
      "parent": 70,
      "props": {
        "id": {
          "type": "value",
          "value": "ModSelectOperatingDatetTimeUX-FormField-71"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "16"
        }
      },
      "seq": 71,
      "void": false
    },
    {
      "from": null,
      "id": 72,
      "name": "div",
      "parent": 71,
      "props": {
        "children": {
          "type": "code",
          "value": "props.timeSpent"
        },
        "id": {
          "type": "value",
          "value": "ModSelectOperatingDatetTimeUX-div-72"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", textAlign: \"center\"}"
        }
      },
      "seq": 72,
      "void": false
    },
    {
      "from": null,
      "id": 73,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "code",
          "value": "`ui form ${props.disabledOn ? \"--override-disabled\" : \"\"}`"
        },
        "id": {
          "type": "value",
          "value": "ModSelectOperatingDatetTimeUX-div-73"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"0px 0px 15px 0px\", display: \"none\"}"
        }
      },
      "seq": 74,
      "void": false
    },
    {
      "from": null,
      "id": 74,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "id": {
          "type": "value",
          "value": "ModSelectOperatingDatetTimeUX-div-74"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"0 15px 15px\"}"
        }
      },
      "seq": 134,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 75,
      "name": "FormGroup",
      "parent": 74,
      "props": {
        "id": {
          "type": "value",
          "value": "ModSelectOperatingDatetTimeUX-FormGroup-75"
        },
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 75,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 76,
      "name": "FormField",
      "parent": 75,
      "props": {
        "id": {
          "type": "value",
          "value": "ModSelectOperatingDatetTimeUX-FormField-76"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "8"
        }
      },
      "seq": 76,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 77,
      "name": "FormField",
      "parent": 75,
      "props": {
        "id": {
          "type": "value",
          "value": "ModSelectOperatingDatetTimeUX-FormField-77"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "8"
        }
      },
      "seq": 77,
      "void": false
    },
    {
      "from": null,
      "id": 78,
      "name": "div",
      "parent": 77,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonList"
        },
        "id": {
          "type": "value",
          "value": "ModSelectOperatingDatetTimeUX-div-78"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", display: \"flex\", justifyContent: props.centeredButton ? \"\":\"flex-end\", marginLeft: props.centeredButton? \"-37.5px\":\"\"}"
        }
      },
      "seq": 78,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 79,
      "name": "FormGroup",
      "parent": 73,
      "props": {
        "id": {
          "type": "value",
          "value": "ModSelectOperatingDatetTimeUX-FormGroup-79"
        },
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 79,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 80,
      "name": "FormField",
      "parent": 79,
      "props": {
        "id": {
          "type": "value",
          "value": "ModSelectOperatingDatetTimeUX-FormField-80"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "1"
        }
      },
      "seq": 80,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 83,
      "name": "FormField",
      "parent": 79,
      "props": {
        "id": {
          "type": "value",
          "value": "ModSelectOperatingDatetTimeUX-FormField-83"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "6"
        }
      },
      "seq": 83,
      "void": false
    },
    {
      "from": null,
      "id": 84,
      "name": "label",
      "parent": 83,
      "props": {
        "children": {
          "type": "code",
          "value": "props.admitCase"
        },
        "id": {
          "type": "value",
          "value": "ModSelectOperatingDatetTimeUX-label-84"
        }
      },
      "seq": 131,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 85,
      "name": "FormField",
      "parent": 79,
      "props": {
        "id": {
          "type": "value",
          "value": "ModSelectOperatingDatetTimeUX-FormField-85"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 85,
      "void": false
    },
    {
      "from": null,
      "id": 86,
      "name": "label",
      "parent": 85,
      "props": {
        "children": {
          "type": "value",
          "value": "นอน รพจำนวน"
        },
        "id": {
          "type": "value",
          "value": "ModSelectOperatingDatetTimeUX-label-86"
        },
        "style": {
          "type": "code",
          "value": "{width: \"96px\"}"
        }
      },
      "seq": 86,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 87,
      "name": "FormField",
      "parent": 79,
      "props": {
        "id": {
          "type": "value",
          "value": "ModSelectOperatingDatetTimeUX-FormField-87"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{paddingRight: 0}"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 87,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 89,
      "name": "FormField",
      "parent": 79,
      "props": {
        "id": {
          "type": "value",
          "value": "ModSelectOperatingDatetTimeUX-FormField-89"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "1"
        }
      },
      "seq": 89,
      "void": false
    },
    {
      "from": null,
      "id": 90,
      "name": "label",
      "parent": 89,
      "props": {
        "children": {
          "type": "value",
          "value": "วัน"
        },
        "id": {
          "type": "value",
          "value": "ModSelectOperatingDatetTimeUX-label-90"
        },
        "style": {
          "type": "code",
          "value": "{textAlign: \"center\", width: \"100%\"}"
        }
      },
      "seq": 90,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 93,
      "name": "Input",
      "parent": 87,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.disabledOn"
        },
        "id": {
          "type": "value",
          "value": "ModSelectOperatingDatetTimeUX-Input-93"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeStayDays"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.stayDays"
        }
      },
      "seq": 88,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 94,
      "name": "FormGroup",
      "parent": 73,
      "props": {
        "id": {
          "type": "value",
          "value": "ModSelectOperatingDatetTimeUX-FormGroup-94"
        },
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 94,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 95,
      "name": "FormField",
      "parent": 94,
      "props": {
        "id": {
          "type": "value",
          "value": "ModSelectOperatingDatetTimeUX-FormField-95"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "1"
        }
      },
      "seq": 95,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 98,
      "name": "FormField",
      "parent": 94,
      "props": {
        "id": {
          "type": "value",
          "value": "ModSelectOperatingDatetTimeUX-FormField-98"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "6"
        }
      },
      "seq": 98,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 100,
      "name": "FormField",
      "parent": 94,
      "props": {
        "id": {
          "type": "value",
          "value": "ModSelectOperatingDatetTimeUX-FormField-100"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 100,
      "void": false
    },
    {
      "from": null,
      "id": 101,
      "name": "label",
      "parent": 100,
      "props": {
        "children": {
          "type": "value",
          "value": "เวลา"
        },
        "id": {
          "type": "value",
          "value": "ModSelectOperatingDatetTimeUX-label-101"
        },
        "style": {
          "type": "code",
          "value": "{width: \"96px\"}"
        }
      },
      "seq": 101,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 102,
      "name": "FormField",
      "parent": 94,
      "props": {
        "id": {
          "type": "value",
          "value": "ModSelectOperatingDatetTimeUX-FormField-102"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{paddingRight: 0}"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 102,
      "void": false
    },
    {
      "from": null,
      "id": 106,
      "name": "div",
      "parent": 102,
      "props": {
        "children": {
          "type": "code",
          "value": "props.admitTime"
        },
        "id": {
          "type": "value",
          "value": "ModSelectOperatingDatetTimeUX-div-106"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 106,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 107,
      "name": "FormGroup",
      "parent": 73,
      "props": {
        "id": {
          "type": "value",
          "value": "ModSelectOperatingDatetTimeUX-FormGroup-107"
        },
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 107,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 108,
      "name": "FormField",
      "parent": 107,
      "props": {
        "id": {
          "type": "value",
          "value": "ModSelectOperatingDatetTimeUX-FormField-108"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "1"
        }
      },
      "seq": 108,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 109,
      "name": "FormField",
      "parent": 107,
      "props": {
        "id": {
          "type": "value",
          "value": "ModSelectOperatingDatetTimeUX-FormField-109"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "6"
        }
      },
      "seq": 109,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 110,
      "name": "Checkbox",
      "parent": 109,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.checkedRequestICU"
        },
        "disabled": {
          "type": "code",
          "value": "props.disabledOn"
        },
        "id": {
          "type": "value",
          "value": "ModSelectOperatingDatetTimeUX-Checkbox-110"
        },
        "label": {
          "type": "value",
          "value": "Request ICU"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeCheckedRequestICU"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\", padding: \"5px 0px\"}"
        }
      },
      "seq": 110,
      "void": false
    },
    {
      "from": null,
      "id": 111,
      "name": "div",
      "parent": 98,
      "props": {
        "children": {
          "type": "code",
          "value": "props.admitDate"
        },
        "id": {
          "type": "value",
          "value": "ModSelectOperatingDatetTimeUX-div-111"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 133,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 112,
      "name": "FormField",
      "parent": 107,
      "props": {
        "id": {
          "type": "value",
          "value": "ModSelectOperatingDatetTimeUX-FormField-112"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 112,
      "void": false
    },
    {
      "from": null,
      "id": 113,
      "name": "label",
      "parent": 112,
      "props": {
        "children": {
          "type": "value",
          "value": "นอน ICU จำนวน"
        },
        "id": {
          "type": "value",
          "value": "ModSelectOperatingDatetTimeUX-label-113"
        },
        "style": {
          "type": "code",
          "value": "{width: \"96px\"}"
        }
      },
      "seq": 113,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 114,
      "name": "FormField",
      "parent": 107,
      "props": {
        "id": {
          "type": "value",
          "value": "ModSelectOperatingDatetTimeUX-FormField-114"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{paddingRight: 0}"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 114,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 115,
      "name": "Input",
      "parent": 114,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.disabledOn"
        },
        "id": {
          "type": "value",
          "value": "ModSelectOperatingDatetTimeUX-Input-115"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeStayDaysICU"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.stayDaysICU"
        }
      },
      "seq": 115,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 116,
      "name": "FormField",
      "parent": 107,
      "props": {
        "id": {
          "type": "value",
          "value": "ModSelectOperatingDatetTimeUX-FormField-116"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "1"
        }
      },
      "seq": 116,
      "void": false
    },
    {
      "from": null,
      "id": 117,
      "name": "label",
      "parent": 116,
      "props": {
        "children": {
          "type": "value",
          "value": "วัน"
        },
        "id": {
          "type": "value",
          "value": "ModSelectOperatingDatetTimeUX-label-117"
        },
        "style": {
          "type": "code",
          "value": "{textAlign: \"center\", width: \"100%\"}"
        }
      },
      "seq": 117,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 118,
      "name": "FormField",
      "parent": 47,
      "props": {
        "id": {
          "type": "value",
          "value": "ModSelectOperatingDatetTimeUX-FormField-118"
        },
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 118,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 119,
      "name": "FormField",
      "parent": 47,
      "props": {
        "id": {
          "type": "value",
          "value": "ModSelectOperatingDatetTimeUX-FormField-119"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 119,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 120,
      "name": "FormField",
      "parent": 47,
      "props": {
        "id": {
          "type": "value",
          "value": "ModSelectOperatingDatetTimeUX-FormField-120"
        },
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 120,
      "void": false
    },
    {
      "from": null,
      "id": 121,
      "name": "label",
      "parent": 119,
      "props": {
        "children": {
          "type": "value",
          "value": "วิธีระงับความรู้สึก"
        },
        "id": {
          "type": "value",
          "value": "ModSelectOperatingDatetTimeUX-label-121"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        }
      },
      "seq": 121,
      "void": false
    },
    {
      "from": null,
      "id": 122,
      "name": "div",
      "parent": 120,
      "props": {
        "children": {
          "type": "code",
          "value": "props.anesthesiaMethod || \"-\""
        },
        "id": {
          "type": "value",
          "value": "ModSelectOperatingDatetTimeUX-div-122"
        }
      },
      "seq": 122,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 123,
      "name": "FormField",
      "parent": 53,
      "props": {
        "id": {
          "type": "value",
          "value": "ModSelectOperatingDatetTimeUX-FormField-123"
        },
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 123,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 124,
      "name": "FormField",
      "parent": 53,
      "props": {
        "id": {
          "type": "value",
          "value": "ModSelectOperatingDatetTimeUX-FormField-124"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 124,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 125,
      "name": "FormField",
      "parent": 53,
      "props": {
        "id": {
          "type": "value",
          "value": "ModSelectOperatingDatetTimeUX-FormField-125"
        },
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 125,
      "void": false
    },
    {
      "from": null,
      "id": 126,
      "name": "label",
      "parent": 124,
      "props": {
        "children": {
          "type": "value",
          "value": "Procedure Type"
        },
        "id": {
          "type": "value",
          "value": "ModSelectOperatingDatetTimeUX-label-126"
        }
      },
      "seq": 126,
      "void": false
    },
    {
      "from": null,
      "id": 127,
      "name": "div",
      "parent": 125,
      "props": {
        "children": {
          "type": "code",
          "value": "props.procedureType || \"-\""
        },
        "id": {
          "type": "value",
          "value": "ModSelectOperatingDatetTimeUX-div-127"
        }
      },
      "seq": 127,
      "void": false
    },
    {
      "from": null,
      "id": 128,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "ModSelectOperatingDatetTimeUX-div-128"
        },
        "style": {
          "type": "code",
          "value": "{position: \"absolute\", right: 0}"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": null,
      "id": 130,
      "name": "div",
      "parent": 128,
      "props": {
        "children": {
          "type": "code",
          "value": "props.statusName"
        },
        "id": {
          "type": "value",
          "value": "ModSelectOperatingDatetTimeUX-div-130"
        }
      },
      "seq": 130,
      "void": false
    },
    {
      "from": null,
      "id": 131,
      "name": "label",
      "parent": 83,
      "props": {
        "children": {
          "type": "value",
          "value": "นัดหมาย Admit"
        },
        "id": {
          "type": "value",
          "value": "ModSelectOperatingDatetTimeUX-label-131"
        },
        "style": {
          "type": "code",
          "value": "{width: \"92px\"}"
        }
      },
      "seq": 84,
      "void": false
    },
    {
      "from": null,
      "id": 133,
      "name": "label",
      "parent": 98,
      "props": {
        "children": {
          "type": "value",
          "value": "วันที่ Admit"
        },
        "id": {
          "type": "value",
          "value": "ModSelectOperatingDatetTimeUX-label-133"
        },
        "style": {
          "type": "code",
          "value": "{width: \"92px\"}"
        }
      },
      "seq": 111,
      "void": false
    },
    {
      "from": null,
      "id": 134,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "code",
          "value": "`ui form ${props.disabledOn ? \"--override-disabled\" : \"\"}`"
        },
        "id": {
          "type": "value",
          "value": "ModSelectOperatingDatetTimeUX-div-134"
        },
        "style": {
          "type": "code",
          "value": "{paddingLeft: \"30px\"}"
        }
      },
      "seq": 128,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 135,
      "name": "FormGroup",
      "parent": 134,
      "props": {
        "id": {
          "type": "value",
          "value": "ModSelectOperatingDatetTimeUX-FormGroup-135"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 135,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 136,
      "name": "FormGroup",
      "parent": 134,
      "props": {
        "id": {
          "type": "value",
          "value": "ModSelectOperatingDatetTimeUX-FormGroup-136"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ display: props.isAdmit? \"\": \"none\" }"
        }
      },
      "seq": 136,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 137,
      "name": "FormGroup",
      "parent": 134,
      "props": {
        "id": {
          "type": "value",
          "value": "ModSelectOperatingDatetTimeUX-FormGroup-137"
        },
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 137,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 138,
      "name": "FormField",
      "parent": 135,
      "props": {
        "id": {
          "type": "value",
          "value": "ModSelectOperatingDatetTimeUX-FormField-138"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 139,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 139,
      "name": "FormField",
      "parent": 135,
      "props": {
        "id": {
          "type": "value",
          "value": "ModSelectOperatingDatetTimeUX-FormField-139"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ display: props.isAdmit? \"\": \"none\" }"
        },
        "width": {
          "type": "code",
          "value": "5"
        }
      },
      "seq": 140,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 140,
      "name": "FormField",
      "parent": 135,
      "props": {
        "id": {
          "type": "value",
          "value": "ModSelectOperatingDatetTimeUX-FormField-140"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ display: props.isAdmit? \"\": \"none\" }"
        },
        "width": {
          "type": "code",
          "value": "6"
        }
      },
      "seq": 166,
      "void": false
    },
    {
      "from": null,
      "id": 143,
      "name": "label",
      "parent": 138,
      "props": {
        "children": {
          "type": "value",
          "value": "Case : "
        },
        "id": {
          "type": "value",
          "value": "ModSelectOperatingDatetTimeUX-label-143"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 143,
      "void": false
    },
    {
      "from": null,
      "id": 144,
      "name": "label",
      "parent": 138,
      "props": {
        "children": {
          "type": "code",
          "value": "props.case || \"\""
        },
        "id": {
          "type": "value",
          "value": "ModSelectOperatingDatetTimeUX-label-144"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", justifyContent: \"center\" }"
        }
      },
      "seq": 144,
      "void": false
    },
    {
      "from": null,
      "id": 145,
      "name": "div",
      "parent": 139,
      "props": {
        "children": {
          "type": "code",
          "value": "props.admitDate"
        },
        "id": {
          "type": "value",
          "value": "ModSelectOperatingDatetTimeUX-div-145"
        }
      },
      "seq": 146,
      "void": false
    },
    {
      "from": null,
      "id": 146,
      "name": "label",
      "parent": 139,
      "props": {
        "children": {
          "type": "value",
          "value": "วันที่ นัด Admit"
        },
        "id": {
          "type": "value",
          "value": "ModSelectOperatingDatetTimeUX-label-146"
        },
        "style": {
          "type": "code",
          "value": "{width: \"92px\"}"
        }
      },
      "seq": 145,
      "void": false
    },
    {
      "from": null,
      "id": 147,
      "name": "label",
      "parent": 140,
      "props": {
        "children": {
          "type": "value",
          "value": "เวลา Admit"
        },
        "id": {
          "type": "value",
          "value": "ModSelectOperatingDatetTimeUX-label-147"
        },
        "style": {
          "type": "code",
          "value": "{width: \"92px\"}"
        }
      },
      "seq": 147,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 149,
      "name": "FormField",
      "parent": 136,
      "props": {
        "id": {
          "type": "value",
          "value": "ModSelectOperatingDatetTimeUX-FormField-149"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 150,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 150,
      "name": "FormField",
      "parent": 136,
      "props": {
        "id": {
          "type": "value",
          "value": "ModSelectOperatingDatetTimeUX-FormField-150"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{paddingRight: 0}"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 151,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 151,
      "name": "FormField",
      "parent": 136,
      "props": {
        "id": {
          "type": "value",
          "value": "ModSelectOperatingDatetTimeUX-FormField-151"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "7"
        }
      },
      "seq": 159,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 152,
      "name": "Checkbox",
      "parent": 151,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.checkedRequestICU"
        },
        "id": {
          "type": "value",
          "value": "ModSelectOperatingDatetTimeUX-Checkbox-152"
        },
        "label": {
          "type": "value",
          "value": "Request ICU"
        },
        "name": {
          "type": "value",
          "value": "admitRequestICU"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\", fontWeight: \"bold\" }"
        }
      },
      "seq": 152,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 153,
      "name": "Input",
      "parent": 151,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "id": {
          "type": "value",
          "value": "ModSelectOperatingDatetTimeUX-Input-153"
        },
        "name": {
          "type": "value",
          "value": "admitICUStay"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "style": {
          "type": "code",
          "value": "{width: \"40%\"}"
        },
        "type": {
          "type": "value",
          "value": "number"
        },
        "value": {
          "type": "code",
          "value": "props.stayDaysICU || \"\""
        }
      },
      "seq": 153,
      "void": false
    },
    {
      "from": null,
      "id": 154,
      "name": "label",
      "parent": 151,
      "props": {
        "children": {
          "type": "value",
          "value": "วัน"
        },
        "id": {
          "type": "value",
          "value": "ModSelectOperatingDatetTimeUX-label-154"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\", marginLeft: \"0.5rem\"}"
        }
      },
      "seq": 154,
      "void": false
    },
    {
      "from": null,
      "id": 155,
      "name": "label",
      "parent": 149,
      "props": {
        "children": {
          "type": "value",
          "value": "นอน รพ. จำนวน"
        },
        "id": {
          "type": "value",
          "value": "ModSelectOperatingDatetTimeUX-label-155"
        }
      },
      "seq": 155,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 156,
      "name": "Input",
      "parent": 150,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.disabledOn"
        },
        "id": {
          "type": "value",
          "value": "ModSelectOperatingDatetTimeUX-Input-156"
        },
        "name": {
          "type": "value",
          "value": "admitStayDate"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeStayDays"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.stayDays"
        }
      },
      "seq": 156,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 157,
      "name": "FormField",
      "parent": 136,
      "props": {
        "id": {
          "type": "value",
          "value": "ModSelectOperatingDatetTimeUX-FormField-157"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "1"
        }
      },
      "seq": 157,
      "void": false
    },
    {
      "from": null,
      "id": 158,
      "name": "label",
      "parent": 157,
      "props": {
        "children": {
          "type": "value",
          "value": "วัน"
        },
        "id": {
          "type": "value",
          "value": "ModSelectOperatingDatetTimeUX-label-158"
        },
        "style": {
          "type": "code",
          "value": "{textAlign: \"center\", width: \"100%\"}"
        }
      },
      "seq": 158,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 159,
      "name": "FormField",
      "parent": 136,
      "props": {
        "id": {
          "type": "value",
          "value": "ModSelectOperatingDatetTimeUX-FormField-159"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 149,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 160,
      "name": "FormField",
      "parent": 137,
      "props": {
        "id": {
          "type": "value",
          "value": "ModSelectOperatingDatetTimeUX-FormField-160"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 161,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 161,
      "name": "FormField",
      "parent": 137,
      "props": {
        "id": {
          "type": "value",
          "value": "ModSelectOperatingDatetTimeUX-FormField-161"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "10"
        }
      },
      "seq": 165,
      "void": false
    },
    {
      "from": null,
      "id": 162,
      "name": "label",
      "parent": 160,
      "props": {
        "children": {
          "type": "value",
          "value": "แผนกที่ติดต่อ"
        },
        "id": {
          "type": "value",
          "value": "ModSelectOperatingDatetTimeUX-label-162"
        }
      },
      "seq": 162,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 163,
      "name": "Dropdown",
      "parent": 161,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.disabledDivision"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "id": {
          "type": "value",
          "value": "ModSelectOperatingDatetTimeUX-Dropdown-163"
        },
        "name": {
          "type": "value",
          "value": "division"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "options": {
          "type": "code",
          "value": "props.divisionOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        },
        "value": {
          "type": "code",
          "value": "props.division"
        }
      },
      "seq": 163,
      "void": false
    },
    {
      "from": null,
      "id": 164,
      "name": "div",
      "parent": 140,
      "props": {
        "children": {
          "type": "code",
          "value": "props.admitTime"
        },
        "id": {
          "type": "value",
          "value": "ModSelectOperatingDatetTimeUX-div-164"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        }
      },
      "seq": 164,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 45,
  "isMounted": false,
  "memo": false,
  "name": "ModSelectOperatingDatetTimeUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 60
}

*********************************************************************************** */
